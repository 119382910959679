import { generatePath, useNavigate } from 'react-router-dom';

import { VerificationStep } from '@view/Auth/Profiles';
import { routerViewProfile } from '@view/Auth/Profiles/router';

export const useGoToVerificationPage = () => {
  const navigate = useNavigate();

  const goToVerificationPage = () => {
    navigate(generatePath(routerViewProfile.path, { subPageParam: VerificationStep.STEP_1 }));
  };

  return goToVerificationPage;
};
