/* eslint-disable no-unused-vars */
import './style.scss';

import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Shield } from 'react-feather';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { ClockIcon, KeyIcon } from '@assets/icon';
import { getRelativeTime } from '@helper/functions';
import authenticationPresenter from '@modules/authentication/presenter';
import { UserSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import { ETwoFaStatus, EUserType } from '@modules/users/usersEntity';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import { LoginActivityStep, VerificationStep } from '../..';
import { routerViewProfile } from '../../router';
import ModalChangePassWord from '../ModalChangePassWord';
import TitleUnderline from '../TitleUnderline';
import FeatureItem from './components/FeatureItem';

export enum EActionFeature {
  CHANGE_PASSWORD = 'change-password',
  VERIFICATION = 'verification',
  LOGIN_ACTIVITY = 'login-activity',
}

export interface IListFeature {
  action: EActionFeature;
  icon: any;
  title: string;
  text?: string;
}

const Security: React.FC = () => {
  const { formatMessage } = useAltaIntl();
  const navigate = useNavigate();
  const [isOpenModalPw, setOpenIsModalPw] = useState<boolean>(false);
  const device = useDeviceType();
  const { language } = useSelector(LanguageSelector);
  const { user } = useSelector(UserSelector);

  const listFeature: IListFeature[] = [
    {
      action: EActionFeature.CHANGE_PASSWORD,
      icon: <KeyIcon />,
      title: 'profile.menu.security.feature.pw.title',
      text: !user?.passwordModifiedAt
        ? formatMessage('profile.menu.security.feature.pw.text')
        : getRelativeTime(2, user?.passwordModifiedAt, language),
    },
    {
      action: EActionFeature.VERIFICATION,
      icon: <Shield className="shield-icon" />,
      title: 'profile.menu.security.feature.veri.title',
      text:
        user?.twoFaStatus === ETwoFaStatus.Enabled
          ? getRelativeTime(3, user?.enabledTwoFatorTime, language)
          : formatMessage('profile.menu.security.feature.veri.text'),
    },
    {
      action: EActionFeature.LOGIN_ACTIVITY,
      icon: <ClockIcon />,
      title: 'profile.menu.security.feature.activity.title',
      text: user?.lastLoginTime ? getRelativeTime(4, user?.lastLoginTime, language) : '',
    },
  ];

  const handleOnClick = (_action: EActionFeature) => {
    if (_action === EActionFeature.CHANGE_PASSWORD) {
      setOpenIsModalPw(true);
    } else if (_action === EActionFeature.VERIFICATION) {
      navigate(generatePath(routerViewProfile.path, { subPageParam: VerificationStep.STEP_1 }));
    } else if (_action === EActionFeature.LOGIN_ACTIVITY) {
      navigate(generatePath(routerViewProfile.path, { subPageParam: LoginActivityStep.STEP_1 }));
    }
  };

  useEffect(() => {
    authenticationPresenter.getProfile();
  }, []);

  return (
    <div className="security-page">
      <div className="w-full h-full flex flex-row">
        <div className="main-setting">
          {device === ETypeDevice.DESKTOP && <TitleUnderline text="profile.menu.security" />}
          <div className="security-page_layout">
            <div className="security-page_layout_head">
              <Typography.Paragraph
                ellipsis={{ rows: 1, tooltip: false }}
                className="security-page_layout_head_title"
              >
                {formatMessage('profile.menu.security.title')}
              </Typography.Paragraph>
              <p className="security-page_layout_head_text">
                {formatMessage('profile.menu.security.text')}
              </p>
            </div>
            <div className="security-page_layout_bot">
              {listFeature?.map((it: IListFeature, index: number) => {
                if (
                  user?.userType === EUserType.Admin &&
                  it.action === EActionFeature.VERIFICATION
                ) {
                  return <></>;
                }
                return <FeatureItem key={index} item={it} handleOnClick={handleOnClick} />;
              })}
            </div>
          </div>
        </div>
      </div>
      <ModalChangePassWord isModalVisible={isOpenModalPw} setIsModalVisible={setOpenIsModalPw} />
    </div>
  );
};

export default React.memo(Security);
