/* eslint-disable no-unused-vars */
import './style.scss';

import { message, Modal, UploadProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';

import authenticationPresenter from '@modules/authentication/presenter';
import { LanguageSelector } from '@modules/setting/settingStore';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import ButtonsAuth from '../../../ButtonsAuth';
import AvatarUser from './components/AvatarUser';
import ButtonsUpload from './components/ButtonsUpload';

export enum ETypeAvatar {
  ADD = 'avatar-add',
  UPDATE = 'avatar-update',
  CONFIRM = 'avatar-confirm',
  DELETE = 'avatar-delete',
}

export interface IAvatarModal {
  isVisible: boolean;
  dataEdit?: any;
}

export interface AvatarModalProps {
  modal: IAvatarModal;
  setModal: (args?: any) => void;
  handleOnCancel?: () => void;
  handleOnOk?: (...args: any) => void;
  className?: string;
}

const AvatarModal: React.FC<AvatarModalProps> = props => {
  const { modal, handleOnCancel, className, setModal } = props;
  const { formatMessage } = useAltaIntl();
  const device = useDeviceType();
  const [typePage, setTypePage] = useState<ETypeAvatar>(ETypeAvatar.ADD);
  const [fileInfo, setFileInfo] = useState<any>(null);
  const { language } = useSelector(LanguageSelector);
  const updateAvatarCall = useSingleAsync(authenticationPresenter.updateAvatar);
  const deleteAvatarCall = useSingleAsync(authenticationPresenter.deleteAvatar);

  const avatarData = useMemo(() => {
    return modal?.dataEdit?.avatar;
  }, [modal]);

  const isAddOrUpdate: boolean = useMemo(() => {
    if (typePage === ETypeAvatar.ADD || typePage === ETypeAvatar.UPDATE) {
      return true;
    }
    return false;
  }, [typePage]);

  const refreshProfile = () => {
    authenticationPresenter.getProfile();
  };

  const handleCancel = () => {
    setModal({ isVisible: false });
    setTypePage(ETypeAvatar.ADD);
    setFileInfo(null);
    handleOnCancel?.();
  };

  const handleCancelUpload = () => {
    setTypePage(ETypeAvatar.DELETE);
  };

  const handleOkUpload = (_typePage: ETypeAvatar, file?: any) => {
    const { uid, lastModified, lastModifiedDate, name, size, type } = file;
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
    const maxSizeInBytes = 30 * 1024 * 1024;

    const condition1 = type && !validTypes.includes(type);
    const condition2 = Number(size) > maxSizeInBytes;

    if (condition1 || condition2) {
      if (condition1) {
        message.error(formatMessage('profile.modal.avatar.des.2'));
      }

      if (condition2) {
        message.error(formatMessage('profile.account.modal.avatar.required.capacity'));
      }
    } else {
      const _fileInfo: any = {
        file,
        uid,
        lastModified,
        lastModifiedDate,
        name,
        size,
        type,
        url: URL.createObjectURL(file),
      };

      setFileInfo(_fileInfo);
      if (_typePage !== ETypeAvatar.CONFIRM) {
        setTypePage(ETypeAvatar.CONFIRM);
      }
    }
  };

  const propsUpload: UploadProps = {
    accept: 'image/*',
    listType: 'picture',
    maxCount: 1,
    beforeUpload: (f?: any) => handleOkUpload(typePage, f),
  };

  const handleCallApi = (_file: any) => {
    updateAvatarCall
      .execute({ avatar: _file })
      .then(() => {
        handleCancel();
        refreshProfile();
      })
      .catch(e => console.error(e));
  };

  const handleCancelConfirm = (_isX: boolean, _typePage?: ETypeAvatar, _avatarData?: any) => {
    if (_isX || _typePage === ETypeAvatar.ADD || _typePage === ETypeAvatar.UPDATE) {
      handleCancel();
    } else if (_typePage === ETypeAvatar.CONFIRM) {
      setFileInfo(null);
      if (!_avatarData) {
        setTypePage(ETypeAvatar.ADD);
      } else {
        setTypePage(ETypeAvatar.UPDATE);
      }
    } else if (_typePage === ETypeAvatar.DELETE) {
      setTypePage(ETypeAvatar.UPDATE);
    }
  };

  const handleOnOkConfirm = (_typePage: ETypeAvatar, _fileInfo: any) => {
    if (_typePage === ETypeAvatar.CONFIRM) {
      handleCallApi(_fileInfo?.file);
    } else if (_typePage === ETypeAvatar.DELETE) {
      deleteAvatarCall
        .execute()
        .then(() => {
          handleCancel();
          refreshProfile();
        })
        .catch(e => console.error(e));
    }
  };

  useEffect(() => {
    if (!avatarData) {
      setTypePage(ETypeAvatar.ADD);
    } else {
      setTypePage(ETypeAvatar.UPDATE);
    }
  }, [avatarData]);

  const titleModal: string = useMemo(() => {
    if (typePage === ETypeAvatar.CONFIRM) {
      return 'profile.account.modal.avatar.title.confirm';
    } else if (typePage === ETypeAvatar.DELETE) {
      return 'profile.account.modal.avatar.title.delete';
    }

    return 'profile.account.modal.avatar.title.avatar';
  }, [modal, typePage, language]);

  const desModal: string = useMemo(() => {
    if (typePage === ETypeAvatar.DELETE) {
      return 'profile.account.modal.avatar.delete.desc';
    }

    return 'profile.modal.avatar.des.2';
  }, [modal, typePage, language]);

  const okText: string | undefined = useMemo(() => {
    if (typePage === ETypeAvatar.ADD) {
      return 'profile.account.modal.avatar.btn.add';
    } else if (typePage === ETypeAvatar.UPDATE) {
      return 'profile.account.modal.avatar.btn.update';
    }
  }, [modal, typePage, language]);

  return (
    <Modal
      centered={device !== ETypeDevice.DESKTOP}
      open={modal.isVisible}
      onCancel={() => handleCancelConfirm(true)}
      closeIcon={<X />}
      className={`avatar-modal ${className != null ? className : ''}`}
      footer={<></>}
      destroyOnClose
      maskClosable={false}
    >
      <div className="avatar-modal-content">
        <div className="avatar-modal-content_layout">
          <h1 className="avatar-modal-content_layout_title">{formatMessage(`${titleModal}`)}</h1>
          <p className="avatar-modal-content_layout_text-1">
            {formatMessage('profile.account.modal.avatar.desc')}
          </p>
          <AvatarUser
            propsUpload={propsUpload}
            avatarData={avatarData}
            fileInfo={fileInfo}
            typePage={typePage}
          />
          <div className="avatar-modal-content_layout_text-2">{formatMessage(`${desModal}`)}</div>
        </div>
        {isAddOrUpdate ? (
          <ButtonsUpload
            propsUpload={propsUpload}
            className={typePage}
            handleCancel={handleCancelUpload}
            handleOk={handleOkUpload}
            cancelText={'common.delete'}
            okText={okText}
            isHiddenCancel={typePage === ETypeAvatar.ADD}
          />
        ) : (
          <ButtonsAuth
            handleCancel={() => handleCancelConfirm(false, typePage, avatarData)}
            handleOk={() => handleOnOkConfirm(typePage, fileInfo)}
          />
        )}
      </div>
    </Modal>
  );
};

export default React.memo(AvatarModal);
