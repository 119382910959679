import './style.scss';

import { Button } from 'antd';
import React from 'react';
import { Plus } from 'react-feather';

import { useAltaIntl } from '@shared/hook/useTranslate';

export interface ButtonPlusProps {
  className?: string;
  okText: string;
  handleOnClick: () => void;
}

const ButtonPlus: React.FC<ButtonPlusProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <Button
      onClick={props.handleOnClick}
      className={`btn-plus-compo ${!props?.className ? '' : props?.className}`}
    >
      <Plus />
      <div className="btn-plus-compo-wrap-text">{formatMessage(`${props.okText}`)}</div>
    </Button>
  );
};

export default React.memo(ButtonPlus);
