import React from 'react';

import Icon from '@ant-design/icons';

const RefreshSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_13932_49240)">
      <path
        d="M14.375 8.125H18.125V4.375"
        stroke="#F26D21"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.7183 15C13.7355 15.9274 12.5013 16.5446 11.1697 16.7745C9.83816 17.0044 8.46838 16.8368 7.23153 16.2926C5.99468 15.7485 4.9456 14.852 4.21537 13.715C3.48513 12.5781 3.10614 11.2511 3.12572 9.90004C3.14531 8.54893 3.56262 7.23355 4.3255 6.11825C5.08838 5.00295 6.16301 4.13719 7.41512 3.62914C8.66723 3.12109 10.0413 2.99329 11.3656 3.26169C12.6899 3.5301 13.9058 4.18281 14.8613 5.13828L18.1246 8.125"
        stroke="#F26D21"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13932_49240">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const RefreshIcon = props => <Icon component={RefreshSvg} {...props} />;
export default RefreshIcon;
