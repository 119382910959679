import { Button } from 'antd';
import React from 'react';
import { Download, Trash } from 'react-feather';
import { useSelector } from 'react-redux';

/* eslint-disable no-unused-vars */
import { RefreshIcon } from '@assets/icon';
import { getRelativeTime } from '@helper/functions';
import { LanguageSelector } from '@modules/setting/settingStore';
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface ActionsProps {
  authCode?: any;
  verificationId?: string;
  handleDownload: (...arg: any) => void;
  handleAddNew: () => void;
  handleDelete: () => void;
}

const Actions: React.FC<ActionsProps> = props => {
  const { formatMessage, intl } = useAltaIntl();
  const { language } = useSelector(LanguageSelector);

  return (
    <div className="backup-code_layout_main_action">
      <div className="backup-code_layout_main_action_left">
        <div className="backup-code_layout_main_action_left_title">
          {intl.formatMessage(
            { id: 'profile.app.info.backup-code.action.title' },
            {
              num: (
                <span className="num-active">
                  {
                    props?.authCode?.recoveryCodes?.filter((it: any) => it?.isUsed === false)
                      ?.length
                  }
                </span>
              ),
            },
          )}
        </div>
        <div className="backup-code_layout_main_action_left_text">
          {getRelativeTime(5, props?.authCode?.createdAt, language)}
        </div>
      </div>
      <div className="backup-code_layout_main_action_right">
        <Button onClick={() => props.handleDownload(props?.authCode, props.verificationId)}>
          <div>{formatMessage('profile.app.info.backup-code.action.btn.download')}</div>
          <Download />
        </Button>
        <Button onClick={props.handleAddNew}>
          <div>{formatMessage('profile.app.info.backup-code.action.btn.refresh')}</div>
          <RefreshIcon />
        </Button>
        <Button onClick={props.handleDelete} className="backup-code-btn-delete">
          <Trash />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Actions);
