/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-vars */
import './style.scss';

import { Button, Checkbox, Typography } from 'antd';
import React, { useMemo } from 'react';
import { ChevronRight, LogOut } from 'react-feather';
import { useSelector } from 'react-redux';

import { LanguageSelector } from '@modules/setting/settingStore';
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface ActivityCardProps {
  isDetailPage?: boolean;
  isPrimary?: boolean;
  id?: string;
  className?: string;
  name?: string;
  text?: string;
  desc?: string;
  descActive?: boolean;
  isSelectedLogout?: boolean;
  listSelected?: string[];
  handleChecked?: (arg?: any) => void;
  goToDetail?: (arg?: any) => void;
  handleLogoutDetail?: (arg?: any) => void;
}

const ActivityCard: React.FC<ActivityCardProps> = props => {
  const { formatMessage } = useAltaIntl();
  const { language } = useSelector(LanguageSelector);

  const logoutCondition: boolean = useMemo(() => {
    if (props.isPrimary || !props.isSelectedLogout) {
      return false;
    }

    return true;
  }, [props]);

  const isActiveItem: boolean = useMemo(() => {
    return !!(props?.id && props?.listSelected?.length && props?.listSelected.includes(props.id));
  }, [props]);

  const handleOnClick = (_condition: boolean, _id?: string) => {
    if (!_condition && !props?.isDetailPage) {
      props.goToDetail && props.goToDetail(_id);
    } else if (_condition) {
      props.handleChecked && props.handleChecked(_id);
    }
  };

  const handleLogout = (_id?: string) => {
    props.handleLogoutDetail && props.handleLogoutDetail(_id);
  };

  const ActionCheck: React.ReactNode = useMemo(() => {
    if (props.isPrimary || !props.isSelectedLogout) {
      return <></>;
    }
    return <Checkbox checked={isActiveItem} className="activity-card_left_check" />;
  }, [props, isActiveItem]);

  const ActionDetail: React.ReactNode = useMemo(() => {
    if (props?.isPrimary && props?.isDetailPage) {
      return (
        <div className="activity-card_right">
          <Button onClick={() => handleLogout(props?.id)} className="btn-select">
            <div className="btn-select-text">{formatMessage('common.logout')}</div>
            <LogOut />
          </Button>
        </div>
      );
    }
    if (!logoutCondition && !props?.isDetailPage) {
      return <div className="activity-card_right">{<ChevronRight />}</div>;
    }

    return <></>;
  }, [props, language]);

  return (
    <div
      key={props?.id}
      className={`activity-card ${!logoutCondition ? '' : 'activity-card-logout'} ${
        !isActiveItem ? '' : 'active-logout'
      } ${!props?.className ? '' : props?.className}`}
      onClick={() => handleOnClick(logoutCondition, props?.id)}
    >
      <div className="activity-card_left">
        {ActionCheck}
        <div className="activity-card_left_wrap">
          <div className="activity-card_left_wrap_top">
            {props?.name != null && (
              <Typography.Paragraph
                ellipsis={{ rows: 1, tooltip: false }}
                className="activity-card_left_wrap_top_name"
              >
                {props?.name}
              </Typography.Paragraph>
            )}
            {props?.text != null && (
              <Typography.Paragraph
                ellipsis={{ rows: 1, tooltip: false }}
                className="activity-card_left_wrap_top_address"
              >
                {props?.text}
              </Typography.Paragraph>
            )}
          </div>
          <div className="activity-card_left_wrap_bot">
            {props?.desc != null && (
              <Typography.Paragraph
                ellipsis={{ rows: 1, tooltip: false }}
                className={`activity-card_left_wrap_bot_desc ${!props?.descActive ? '' : 'active'}`}
              >
                {!props?.descActive ? props?.desc : formatMessage(`${props?.desc}`)}
              </Typography.Paragraph>
            )}
          </div>
        </div>
      </div>
      {ActionDetail}
    </div>
  );
};

export default React.memo(ActivityCard);
