/* eslint-disable no-unused-vars */
import './style.scss';

import { Button, Upload, UploadProps } from 'antd';
import React from 'react';

import { useAltaIntl } from '@shared/hook/useTranslate';

export interface ButtonsUploadProps {
  propsUpload: UploadProps;
  handleCancel: () => void;
  handleOk: (args?: any) => void;
  className?: string;
  cancelText?: string;
  okText?: string;
  isInform?: boolean;
  isHiddenCancel?: boolean;
  isHiddenOk?: boolean;
}

const ButtonsUpload: React.FC<ButtonsUploadProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <div className={`buttons-upload ${!props?.className ? '' : props?.className}`}>
      {!props?.isHiddenCancel && (
        <Button
          id="btn-upload-cancel"
          onClick={props.handleCancel}
          className="buttons-upload-cancel"
        >
          {!props?.cancelText
            ? formatMessage('common.back')
            : formatMessage(`${props?.cancelText}`)}
        </Button>
      )}
      {!props?.isHiddenOk && (
        <Upload className="buttons-upload-up" {...props.propsUpload}>
          <Button id="btn-upload-ok" className="buttons-upload-up-ok">
            {!props?.okText
              ? formatMessage('common.button.accept')
              : formatMessage(`${props?.okText}`)}
          </Button>
        </Upload>
      )}
    </div>
  );
};

export default React.memo(ButtonsUpload);
