/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import './style.scss';

/* eslint-disable no-unused-vars */
import { Form, Modal } from 'antd';
import React, { useState } from 'react';
import { X } from 'react-feather';
import OtpInput from 'react-otp-input';
import { useParams } from 'react-router-dom';

import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import ButtonsAuth from '../../../ButtonsAuth';

export interface IModalAddCode {
  isVisible: boolean;
}

export interface ModalAddCodeProps {
  form: any;
  modal: IModalAddCode;
  setModal: (args?: any) => void;
  className?: string;
  handleCallApi: (...arg: any) => void;
  handleOnCancel: () => void;
}

const ModalAddCode: React.FC<ModalAddCodeProps> = props => {
  const { form, modal, handleOnCancel, className, handleCallApi } = props;
  const { formatMessage } = useAltaIntl();
  const device = useDeviceType();
  const { verificationId } = useParams();
  const [optValue, setOtpValue] = useState<string>('');

  const handleOnCancelModal = () => {
    setOtpValue('');
    handleOnCancel();
  };

  const handleOnOk = () => {
    form.submit();
  };

  const handleOtpChange = (_otp: string) => {
    setOtpValue(_otp);
  };

  const onFinish = (values: any) => {
    if (!values?.authCode || values?.authCode?.length < 6 || !verificationId) {
      return;
    }

    const newValues = {
      twoFaMethodId: verificationId,
      code: values?.authCode,
    };

    handleCallApi(newValues, verificationId);
  };

  return (
    <Modal
      centered={device !== ETypeDevice.DESKTOP}
      open={modal.isVisible}
      onCancel={handleOnCancelModal}
      closeIcon={<X />}
      className={`modal-add-code ${className != null ? className : ''}`}
      footer={<></>}
      destroyOnClose
    >
      <div className="modal-add-code-content">
        <div className="modal-add-code-content_layout">
          <h1 className="modal-add-code-content_layout_title">
            {formatMessage('profile.add.new.modal.add.new.code.title')}
          </h1>
          <div className="modal-add-code-content_layout_wrap">
            <p className="modal-add-code-content_layout_wrap_text-1">
              {formatMessage('profile.add.new.modal.add.new.code.text-1')}
            </p>
            <p className="modal-add-code-content_layout_wrap_text-2">
              {formatMessage('profile.add.new.modal.add.new.code.text-2')}
            </p>
          </div>
        </div>
        <Form
          className="main-form"
          layout="vertical"
          name="formAuthCode"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item name={'authCode'}>
            <OtpInput
              value={optValue}
              onChange={handleOtpChange}
              numInputs={6}
              inputStyle={'auth-code-input'}
              containerStyle={'auth-code-container'}
              inputType="number"
              renderInput={(props: any) => <input {...props} />}
            />
          </Form.Item>
        </Form>
        <ButtonsAuth
          handleCancel={handleOnCancelModal}
          handleOk={handleOnOk}
          propsOk={{ disabled: optValue?.length < 6 }}
        />
      </div>
    </Modal>
  );
};

export default React.memo(ModalAddCode);
