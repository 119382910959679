import { Key } from 'react';

import { PaginationEntity } from '@core/pagination/entity';
import httpRepository, { TShowError } from '@core/repository/http';
import { OptionEntity, TableEntity } from '@core/table';
import ApplicationsEntity from '@modules/applications/applicationsEntity';
import TwoFasEntity from '@modules/twoFas/twoFasEntity';

import UserDeviceLoginEntity from './userDeviceLoginEntity';
import UsersEntity from './usersEntity';

// API GET
export const getListUsers = (pagination: PaginationEntity, options: OptionEntity) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: '/api/Users',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: UsersEntity.createListUsers(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};

//and get detail
export const getDetailUsers = (id: string) => {
  return httpRepository.execute({
    path: '/api/Users/' + id,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new UsersEntity(res);
    },
  });
};

export const getListUsersMeTwoFa = () => {
  return httpRepository.execute({
    path: '/api/Users/Me/TwoFa',
    showSuccess: false,
    showError: false,
    convert: res => {
      return {
        data: res,
      };
    },
  });
};

export const getDetailUsersMeTwoFa = (id?: string) => {
  return httpRepository.execute({
    path: '/api/Users/Me/TwoFa/' + id,
    showSuccess: false,
    showError: false,
    convert: res => {
      return res;
    },
  });
};

export const getUsersMeLoginSessionExpiry = () => {
  return httpRepository.execute({
    path: '/api/Users/me/LoginSession/Expiry',
    showSuccess: false,
    showError: false,
    convert: res => {
      return res;
    },
  });
};

export const createUsersMeTwoFa = (
  payload?: any,
  showSuccess?: boolean,
  showError?: TShowError,
) => {
  return httpRepository.execute({
    path: '/api/Users/Me/TwoFa/Status',
    method: 'post',
    payload,
    showSuccess,
    showError,
  });
};

export const deleteUsersMeTwoFa = (id?: string, showError?: TShowError) => {
  return httpRepository.execute({
    path: '/api/Users/Me/TwoFa/' + id,
    method: 'delete',
    showError,
  });
};

export const createUsersMeTwoFaStep1 = (
  payload?: any,
  showSuccess?: boolean,
  showError?: TShowError,
) => {
  return httpRepository.execute({
    path: '/api/Users/Me/TwoFa',
    method: 'post',
    payload,
    showSuccess,
    showError,
    convert: res => {
      return res;
    },
  });
};

export const createUsersMeTwoFaStep2 = (payload?: any, showError?: TShowError) => {
  return httpRepository.execute({
    path: '/api/Users/Me/TwoFa/Confirm',
    method: 'post',
    payload,
    showError,
    convert: res => {
      return res;
    },
  });
};

export const logoutDevices = (ids?: string[]) => {
  return httpRepository.execute({
    path: '/api/Users/DeviceLogout',
    method: 'put',
    payload: { ids },
  });
};

export const usersVerification = (payload?: any) => {
  return httpRepository.execute({
    path: '/api/Users/Me/TwoFa/Verification',
    method: 'post',
    payload,
    showSuccess: false,
  });
};

export const getListUsersDeviceLogin = (pagination: PaginationEntity, options: OptionEntity) => {
  const params = new TableEntity(pagination, options);

  return httpRepository.execute({
    path: '/api/Users/DeviceLogin',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: UserDeviceLoginEntity.createListUsers(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
        moreInfo: res?.moreInfo,
      };
    },
  });
};

export const getListUsersLoginSession = (pagination: PaginationEntity, options: OptionEntity) => {
  const params = new TableEntity(pagination, options);

  return httpRepository.execute({
    path: '/api/Users/LoginSession',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: UserDeviceLoginEntity.createListUsers(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
        moreInfo: res?.moreInfo,
      };
    },
  });
};

//API ADD
export const createUsers = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Users',
    method: 'post',
    payload,
    config: {
      isFormData: true,
    },
  });
};

//API EDIT/UPDATE
export const updateUsers = (id: string, payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/' + id,
    method: 'put',
    payload,
    config: {
      isFormData: true,
    },
  });
};

//API DELETE
export const deleteUsers = (ids: Key[]) => {
  return httpRepository.execute({
    path: '/api/Users/DeleteMany',
    method: 'post',
    payload: {
      ids,
    },
  });
};
// API GET
export const getListUsersApplication = () => {
  return httpRepository.execute({
    path: '/api/Users/me/Applications',
    showSuccess: false,
    showError: false,
    convert: res => {
      return {
        data: ApplicationsEntity.createListApplications(res?.pagedData),
      };
    },
  });
};

// GET
export const getUserTwoFa = (sessionId: string) => {
  return httpRepository.execute({
    path: '/api/Users/TwoFa/' + sessionId,
    showSuccess: false,
    showError: false,
    convert: res => {
      const sortedData = res.sort((a, b) => a?.type - b?.type);
      return {
        data: TwoFasEntity.createListTwoFas(sortedData),
      };
    },
  });
};
