import React from 'react';

import Icon from '@ant-design/icons';

const ClockSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14008_7247)">
      <path
        d="M12 7.5V12L15.75 14.25"
        stroke="#F26D21"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.75 9.75H3V6"
        stroke="#F26D21"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.3375 18C7.51685 19.1128 8.99798 19.8535 10.5958 20.1294C12.1937 20.4052 13.8374 20.2041 15.3217 19.5512C16.8059 18.8982 18.0648 17.8224 18.9411 16.458C19.8173 15.0937 20.2721 13.5014 20.2486 11.88C20.2251 10.2587 19.7244 8.68026 18.8089 7.3419C17.8934 6.00354 16.6039 4.96462 15.1014 4.35497C13.5988 3.74531 11.95 3.59194 10.3608 3.91403C8.77157 4.23612 7.31253 5.01937 6.16594 6.16593C5.0625 7.28343 4.15125 8.33718 3 9.75"
        stroke="#F26D21"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14008_7247">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ClockIcon = props => <Icon component={ClockSvg} {...props} />;
export default ClockIcon;
