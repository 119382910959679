import React from 'react';

import Icon from '@ant-design/icons';

const KeySvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14008_7241)">
      <path
        d="M8.73469 11.5153C8.41228 10.7131 8.2477 9.85616 8.25 8.99156C8.25 5.37468 11.1637 2.36906 14.7787 2.25375C15.7022 2.22296 16.6222 2.3821 17.4816 2.72131C18.3411 3.06052 19.1218 3.57257 19.7753 4.22575C20.4288 4.87892 20.9413 5.65932 21.281 6.51861C21.6206 7.37789 21.7803 8.29776 21.75 9.22125C21.6309 12.8362 18.6253 15.75 15.0084 15.75C14.1438 15.7523 13.2869 15.5877 12.4847 15.2653L11.25 16.5H9V18.75H6.75V21H3.75C3.55109 21 3.36032 20.921 3.21967 20.7803C3.07902 20.6397 3 20.4489 3 20.25V17.5603C3.00009 17.3617 3.07899 17.1712 3.21938 17.0306L8.73469 11.5153Z"
        stroke="#F26D21"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z"
        fill="#F26D21"
      />
    </g>
    <defs>
      <clipPath id="clip0_14008_7241">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const KeyIcon = props => <Icon component={KeySvg} {...props} />;
export default KeyIcon;
