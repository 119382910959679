/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-vars */
import './style.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { Shield, User } from 'react-feather';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { LogoutOutlined } from '@ant-design/icons';
import CONFIG from '@config';
import { isAfterExpiry, sendBack, sendLogOut } from '@helper/functions';
import { UserSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import UsersPresenter from '@modules/users/usersPresenter';
import { ConfirmReStart } from '@shared/components/ConfirmReStart';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useGoToVerificationPage } from '@shared/hook/useGoToVerificationPage';
import { useLogout } from '@shared/hook/useLogout';
import { useAltaIntl } from '@shared/hook/useTranslate';

import AccountInfo from './components/AccountInfo';
import HeadProfile from './components/HeadProfile';
import LoginActivity from './components/LoginActivity';
import DeviceDetail from './components/LoginActivity/DeviceDetail';
import HeadProfileMobile from './components/Mobile/HeadProfileMobile';
import ModalNotifyExpired, { IModalNotifyExpired } from './components/ModalNotifyExpired';
import Security from './components/Security';
import SettingsSideBar from './components/SettingsSideBar';
import Verification from './components/Verification';
import AddNewApp from './components/Verification/AddNewApp';
import AppInfo from './components/Verification/AppInfo';
import BackupCode from './components/Verification/BackupCode';
import { routerVerificationInfo, routerViewProfile } from './router';

export enum ProfilePage {
  PROFILE_PAGE = 'profile-page',
}

export enum ProfileSubPage {
  ACCOUNT_INFO = 'account-info',
  SECURITY = 'security',
}

export enum ETypeSubSecurityPage {
  VERIFICATION = 'type-verification',
  LOGIN_ACTIVITY = 'type-activity',
}

export enum VerificationStep {
  STEP_1 = 'verification-step-1',
  STEP_2 = 'verification-step-2',
  STEP_3 = 'verification-step-3',
  STEP_4 = 'verification-step-4',
}

export enum BackupCodeStep {
  STEP_1 = 'backup-code-step-1',
}

export enum LoginActivityStep {
  STEP_1 = 'login-activity-step-1',
  STEP_2 = 'login-activity-step-2',
}

export interface ISubPage {
  icon: any;
  name: string;
  param: string;
  component: any;
}

export interface ISubSecurityPages {
  type: ETypeSubSecurityPage;
  param: string;
  component: any;
}

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const { subPageParam, verificationId } = useParams();
  const { language } = useSelector(LanguageSelector);
  const device = useDeviceType(ETypeDevice.DESKTOP, true);
  const [titleMobile, setTitleMobile] = useState<string | undefined>(undefined);
  const { formatMessage } = useAltaIntl();
  const logout = useLogout();
  const goToVerificationPage = useGoToVerificationPage();
  const { user } = useSelector(UserSelector);
  const [modalExpired, setModalExpired] = useState<IModalNotifyExpired>({ isVisible: false });
  const getUsersMeLoginSessionExpiryCall = useSingleAsync(
    UsersPresenter.getUsersMeLoginSessionExpiry,
  );

  const subPages: ISubPage[] = [
    {
      icon: <User />,
      name: 'profile.menu.accountInfo',
      param: ProfileSubPage.ACCOUNT_INFO,
      component: <AccountInfo />,
    },
    {
      icon: <Shield />,
      name: 'profile.menu.security',
      param: ProfileSubPage.SECURITY,
      component: <Security />,
    },
  ];

  const subSecurityPages: ISubSecurityPages[] = [
    {
      type: ETypeSubSecurityPage.VERIFICATION,
      param: VerificationStep.STEP_1,
      component: <Verification />,
    },
    {
      type: ETypeSubSecurityPage.VERIFICATION,
      param: VerificationStep.STEP_2,
      component: <AddNewApp />,
    },
    {
      type: ETypeSubSecurityPage.VERIFICATION,
      param: VerificationStep.STEP_3,
      component: <AppInfo />,
    },
    {
      type: ETypeSubSecurityPage.VERIFICATION,
      param: VerificationStep.STEP_4,
      component: <AddNewApp />,
    },
    {
      type: ETypeSubSecurityPage.VERIFICATION,
      param: BackupCodeStep.STEP_1,
      component: <BackupCode />,
    },
    {
      type: ETypeSubSecurityPage.LOGIN_ACTIVITY,
      param: LoginActivityStep.STEP_1,
      component: <LoginActivity />,
    },
    {
      type: ETypeSubSecurityPage.LOGIN_ACTIVITY,
      param: LoginActivityStep.STEP_2,
      component: <DeviceDetail setTitleMobile={setTitleMobile} />,
    },
  ];

  const fetchUsersMeLoginSessionExpiry = () => {
    return getUsersMeLoginSessionExpiryCall
      .execute()
      .then(rs => rs)
      .catch(e => Promise.reject(e));
  };

  const handleLogout = () => {
    ConfirmReStart({
      okText: formatMessage('common.yes'),
      title: formatMessage('common.logout.title'),
      content: formatMessage('common.logout.content'),
      handleOk: () => {
        sendLogOut();
        logout();
      },
      handleCancel: () => {},
      icon: <LogoutOutlined className="text-white" />,
      modifiers: 'orange',
      centered: true,
    });
  };

  const handleBack = () => {
    const isStep1 =
      subPageParam === VerificationStep.STEP_1 || subPageParam === LoginActivityStep.STEP_1;
    const isLoginActivityStep1WithDevice =
      subPageParam === LoginActivityStep.STEP_2 && verificationId != null && verificationId !== '';

    if (isStep1) {
      navigate(generatePath(routerViewProfile.path, { subPageParam: ProfileSubPage.SECURITY }));
    } else if (isLoginActivityStep1WithDevice) {
      navigate(generatePath(routerViewProfile.path, { subPageParam: LoginActivityStep.STEP_1 }));
    } else if (
      subPageParam === VerificationStep.STEP_2 ||
      subPageParam === VerificationStep.STEP_3 ||
      subPageParam === BackupCodeStep.STEP_1
    ) {
      goToVerificationPage();
    } else if (subPageParam === VerificationStep.STEP_4) {
      fetchUsersMeLoginSessionExpiry()
        .then((rs: any) => {
          if (isAfterExpiry(rs?.expiryTime)) {
            setModalExpired({ isVisible: true });
          } else {
            navigate(
              generatePath(routerVerificationInfo.path, {
                subPageParam: VerificationStep.STEP_3,
                verificationId: verificationId || '',
              }),
            );
          }
        })
        .catch(e => console.error(e));
    } else if (
      (device === ETypeDevice.MOBILE || device === ETypeDevice.TABLET) &&
      subPages?.some((it: ISubPage) => it.param === subPageParam)
    ) {
      navigate(generatePath(routerViewProfile.path, { subPageParam: ProfilePage.PROFILE_PAGE }));
    } else {
      if (device === ETypeDevice.MOBILE || device === ETypeDevice.TABLET) {
        sendBack();
      }
      navigate('/');
    }
  };

  useEffect(() => {
    if (!subPageParam) {
      navigate(generatePath(routerViewProfile.path, { subPageParam: ProfilePage.PROFILE_PAGE }));
    } else if (
      subPageParam === ProfilePage.PROFILE_PAGE &&
      window.innerWidth > CONFIG.TABLET_WIDTH
    ) {
      navigate(generatePath(routerViewProfile.path, { subPageParam: ProfileSubPage.ACCOUNT_INFO }));
    }
  }, [subPageParam]);

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      if (!event?.data?.mobileMessage) {
        return;
      }

      if (event?.data?.mobileMessage === 'MTC_CMS_Mobile') {
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  const LayoutContent: React.ReactNode = useMemo(() => {
    if (!subPageParam) {
      return <></>;
    }

    if (subPageParam === LoginActivityStep.STEP_2 && !verificationId) {
      return <></>;
    }

    const condition1: boolean = subPages?.some((it: ISubPage) => it.param === subPageParam);
    const condition2: boolean = subSecurityPages?.some(
      (it: ISubSecurityPages) => it.param === subPageParam,
    );

    let PageContent: React.ReactNode = <></>;
    const ModalContent: React.ReactNode = (
      <ModalNotifyExpired modal={modalExpired} setModal={setModalExpired} />
    );

    if (condition1) {
      PageContent = subPages?.find((it: ISubPage) => it.param === subPageParam)?.component || <></>;
    } else if (condition2) {
      PageContent = subSecurityPages?.find((it: ISubSecurityPages) => it.param === subPageParam)
        ?.component || <></>;
    } else if (
      (device === ETypeDevice.MOBILE || device === ETypeDevice.TABLET) &&
      subPageParam === ProfilePage.PROFILE_PAGE
    ) {
      PageContent = (
        <SettingsSideBar subPages={subPages} subPageParam={subPageParam} device={device} />
      );
    }

    if (device === ETypeDevice.MOBILE || device === ETypeDevice.TABLET) {
      return (
        <div className="profile-page-mobile_layout">
          {PageContent}
          {ModalContent}
        </div>
      );
    } else if (device === ETypeDevice.DESKTOP) {
      return (
        <div className={`profile-page_layout ${!condition1 ? 'hidden-menu' : ''}`}>
          {condition1 && (
            <div className="profile-page_layout_side-bar">
              <SettingsSideBar subPages={subPages} subPageParam={subPageParam} device={device} />
            </div>
          )}
          <div className="profile-page_layout_pages">
            {PageContent}
            {ModalContent}
          </div>
        </div>
      );
    }

    return <></>;
  }, [subPages, subSecurityPages, subPageParam, verificationId, language, device, user]);

  if (device === ETypeDevice.MOBILE || device === ETypeDevice.TABLET) {
    const isAndroidWebView = /wv/.test(navigator.userAgent);
    const isIOSWebView =
      /\bCriOS\b/.test(navigator.userAgent) || /\bSafari\b/.test(navigator.userAgent) === false;

    const isReactNativeWebView = (isAndroidWebView || isIOSWebView) && window.innerWidth < 601;
    const ratio: number = Number(window.innerWidth) / 375;

    const _style: any = {};

    if (isReactNativeWebView) {
      _style.paddingTop = `calc(10rem * ${ratio})`;
    }

    return (
      <div className="profile-page-mobile" style={_style}>
        <HeadProfileMobile
          titleMobile={titleMobile}
          handleBack={handleBack}
          handleLogout={handleLogout}
          isLogout={subPageParam === ProfilePage.PROFILE_PAGE}
          subPageParam={subPageParam}
          isReactNativeWebView={isReactNativeWebView}
          ratio={ratio}
        />
        {LayoutContent}
      </div>
    );
  } else if (device === ETypeDevice.DESKTOP) {
    return (
      <div className="profile-page">
        <HeadProfile handleBack={handleBack} />
        {LayoutContent}
      </div>
    );
  } else {
    return <></>;
  }
};

export default React.memo(Profile);
