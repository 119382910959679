import './style.scss';

import { Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { LogOut } from 'react-feather';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { getRelativeTime, getSid } from '@helper/functions';
import { UserSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import { ELoggedInStatus } from '@modules/users/userDeviceLoginEntity';
import UsersPresenter from '@modules/users/usersPresenter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useLogout } from '@shared/hook/useLogout';
import { useAltaIntl } from '@shared/hook/useTranslate';

import { LoginActivityStep } from '../..';
import { routerVerificationInfo } from '../../router';
import ModalConfirmAuth, { IModalConfirmAuth } from '../ModalConfirmAuth';
import TitleUnderline from '../TitleUnderline';
import ActivityCard from './ActivityCard';

const LoginActivity: React.FC = () => {
  const navigate = useNavigate();
  const { language } = useSelector(LanguageSelector);
  const { formatMessage, intl } = useAltaIntl();
  const device = useDeviceType();
  const [isSelectedLogout, setIsSelectedLogout] = useState<boolean>(false);
  const [listSelected, setListSelected] = useState<string[]>([]);
  const [dataDeviceLogin, setDataDeviceLogin] = useState<any>(null);
  const [modalConfirmLogout, setModalConfirmLogout] = useState<IModalConfirmAuth>({
    isVisible: false,
  });
  const { user } = useSelector(UserSelector);

  const sid = useMemo(() => {
    return getSid(user?.username);
  }, [user]);

  const logout = useLogout();
  const getListUsersDeviceLoginCall = useSingleAsync(UsersPresenter.getListUsersDeviceLogin);
  const logoutDevicesCall = useSingleAsync(UsersPresenter.logoutDevices);

  const dataPrimary = useMemo(() => {
    return dataDeviceLogin?.data?.find((it: any) => it?.loginSessionId === sid);
  }, [dataDeviceLogin, sid]);

  const listData = useMemo(() => {
    return dataDeviceLogin?.data?.filter((it: any) => it?.loginSessionId !== sid);
  }, [dataDeviceLogin, sid]);

  const fetchDeviceLogin = () => {
    return getListUsersDeviceLoginCall
      .execute({}, { filter: { loggedInStatus: { op: '$eq', value: ELoggedInStatus.Active } } })
      .then((rs: any) => {
        return rs;
      })
      .catch(e => Promise.reject(e));
  };

  const freshData = () => {
    fetchDeviceLogin()
      .then(rs => {
        if (!rs) {
          return;
        }
        setDataDeviceLogin(rs);
      })
      .catch(e => console.error(e));
  };

  const handleChecked = (_idItem: string) => {
    if (!_idItem) {
      return;
    }

    if (listSelected?.includes(_idItem)) {
      setListSelected([...listSelected?.filter((it: string) => it !== _idItem)]);
    } else {
      setListSelected([...listSelected, _idItem]);
    }
  };

  const goToDetail = (_veriId?: string) => {
    if (!_veriId) {
      return;
    }
    navigate(
      generatePath(routerVerificationInfo.path, {
        subPageParam: LoginActivityStep.STEP_2,
        verificationId: _veriId,
      }),
    );
  };

  const handleSelectLogout = () => {
    if (isSelectedLogout) {
      setListSelected([]);
    }
    setIsSelectedLogout(!isSelectedLogout);
  };

  const handleLogoutDevice = () => {
    if (!listSelected || listSelected?.length === 0) {
      return;
    }

    setModalConfirmLogout({ isVisible: true });
  };

  const handleOnCancelConfirmAuth = () => {
    setModalConfirmLogout({ isVisible: false });
  };

  const handleOnOkConfirmAuth = () => {
    if (!listSelected || listSelected?.length === 0) {
      return;
    }

    logoutDevicesCall
      .execute(listSelected)
      .then(() => {
        setIsSelectedLogout(false);
        setListSelected([]);
        setDataDeviceLogin(null);
        setModalConfirmLogout({ isVisible: false });
        freshData();
      })
      .catch(e => console.error(e));
  };

  useEffect(() => {
    freshData();
  }, []);

  useEffect(() => {
    if (!sid) {
      logout();
    }
  }, [sid]);

  const HeadAction: React.ReactNode = useMemo(() => {
    if (!isSelectedLogout) {
      return (
        <div className="login-activities_layout_log_head_action">
          <Button onClick={handleSelectLogout} className="btn-select">
            <div className="btn-select-text">
              {formatMessage('profile.login-activities.action.select')}
            </div>
            <LogOut />
          </Button>
        </div>
      );
    } else {
      return (
        <div className="login-activities_layout_log_head_action">
          <div className="login-activities_layout_log_head_action_quantity">
            {intl.formatMessage(
              { id: 'profile.login-activities.device.quantity' },
              { num: <span className="active">{listSelected?.length}</span> },
            )}
          </div>
          <div className="login-activities_layout_log_head_action_btns">
            <Button onClick={handleSelectLogout} className="btn-back">
              {formatMessage('profile.login-activities.action.back')}
            </Button>
            <Button onClick={handleLogoutDevice} className="btn-confirm">
              {formatMessage('profile.login-activities.action.confirm')}
            </Button>
          </div>
        </div>
      );
    }
  }, [isSelectedLogout, language, listSelected]);

  return (
    <div className="login-activities">
      <div className="w-full h-full flex flex-row">
        <div className="main-setting">
          {device === ETypeDevice.DESKTOP && (
            <TitleUnderline text="profile.login.activity.page.title" />
          )}
          <div className="login-activities_layout">
            <div className="login-activities_layout_now">
              {dataPrimary != null && (
                <ActivityCard
                  key={dataPrimary?.loginSessionId}
                  id={dataPrimary?.loginSessionId}
                  name={dataPrimary?.deviceName}
                  text={dataPrimary?.location}
                  desc={'profile.login-activities.device.active'}
                  descActive={true}
                  goToDetail={goToDetail}
                />
              )}
            </div>
            <div className="login-activities_layout_log">
              <div className="login-activities_layout_log_head">
                <h3 className="login-activities_layout_log_head_title">
                  {formatMessage('profile.login-activities.title')}
                </h3>
                {HeadAction}
              </div>
              <div className="login-activities_layout_log_list">
                {listData != null &&
                  listData?.length > 0 &&
                  listData?.map((it: any) => {
                    return (
                      <div className="login-activities_layout_log_list_item">
                        <ActivityCard
                          key={it?.loginSessionId}
                          id={it?.loginSessionId}
                          name={it?.deviceName}
                          text={it?.location}
                          desc={getRelativeTime(6, it?.createdAt, language)}
                          isSelectedLogout={isSelectedLogout}
                          listSelected={listSelected}
                          handleChecked={handleChecked}
                          goToDetail={goToDetail}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmAuth
        modal={modalConfirmLogout}
        handleOnCancel={handleOnCancelConfirmAuth}
        handleOnOk={handleOnOkConfirmAuth}
        title={'profile.login-activities.detail.modal.confirm.title'}
        content={'profile.login-activities.detail.modal.confirm.content'}
      />
    </div>
  );
};

export default React.memo(LoginActivity);
