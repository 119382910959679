/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';

import CONFIG from '@config';

export enum ETypeDevice {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export const useDeviceType = (layout: ETypeDevice = ETypeDevice.DESKTOP, isNoDefault?: boolean) => {
  const [device, setDevice] = useState<string>(isNoDefault ? '' : layout);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= CONFIG.MOBILE_WIDTH) {
        setDevice(ETypeDevice.MOBILE);
      } else if (
        window.innerWidth > CONFIG.MOBILE_WIDTH &&
        window.innerWidth <= CONFIG.TABLET_WIDTH
      ) {
        setDevice(ETypeDevice.TABLET);
      } else if (window.innerWidth > CONFIG.TABLET_WIDTH) {
        setDevice(ETypeDevice.DESKTOP);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return device;
};
