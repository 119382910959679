import './style.scss';

import { Button } from 'antd';
import React from 'react';

import { useAltaIntl } from '@shared/hook/useTranslate';

export interface ButtonsAuthProps {
  handleCancel: () => void;
  handleOk: () => void;
  className?: string;
  cancelText?: string;
  okText?: string;
  isInform?: boolean;
  isHiddenCancel?: boolean;
  isHiddenOk?: boolean;
  propsOk?: any;
  propsCancel?: any;
}

const ButtonsAuth: React.FC<ButtonsAuthProps> = props => {
  const { formatMessage } = useAltaIntl();

  if (props?.isInform) {
    return (
      <div className={`button-auth ${!props?.className ? '' : props?.className}`}>
        <Button
          {...props?.propsCancel}
          id="btn-auth-cancel"
          onClick={props.handleCancel}
          className="button-auth-cancel"
        >
          {!props?.cancelText ? formatMessage('common.close') : props?.cancelText}
        </Button>
      </div>
    );
  }

  return (
    <div className={`button-auth ${!props?.className ? '' : props?.className}`}>
      {!props?.isHiddenCancel && (
        <Button
          {...props?.propsCancel}
          id="btn-auth-cancel"
          onClick={props.handleCancel}
          className="button-auth-cancel"
        >
          {!props?.cancelText
            ? formatMessage('common.back')
            : formatMessage(`${props?.cancelText}`)}
        </Button>
      )}
      {!props?.isHiddenOk && (
        <Button
          {...props?.propsOk}
          id="btn-auth-ok"
          onClick={props.handleOk}
          className="button-auth-ok"
        >
          {!props?.okText
            ? formatMessage('common.button.accept')
            : formatMessage(`${props?.okText}`)}
        </Button>
      )}
    </div>
  );
};

export default React.memo(ButtonsAuth);
