import dayjs from 'dayjs';

interface IDocumentScripts {
  displayName: string;
  languageCode: string;
  file: string;
}

class ApplicationsEntity {
  //copy props from backend:
  createdAt = '';

  name = '';

  redirectUris: string[] = [];

  logo?: string;

  accountCount?: number;

  id: string = '';

  label: string = '';

  value: string = '';

  key: string = '';

  description = '';

  clientId = '';

  documentScripts?: IDocumentScripts[] = [];

  regex?: string;

  scopes?: string[] = [];

  credentials?: any[] = [];

  webHook?: string;

  order: number = 0;

  constructor(applications: Partial<ApplicationsEntity>) {
    if (!applications) {
      return;
    }
    Object.assign(this, applications);
    // convert entity type here
    this.createdAt = applications.createdAt
      ? dayjs(applications.createdAt).format('DD/MM/YYYY')
      : '';

    this.label = applications.name || '';
    this.value = applications.id || '';
    this.key = applications.id || '';
  }

  static createListApplications(listApplications: Array<Partial<ApplicationsEntity>>) {
    if (!Array.isArray(listApplications)) {
      return [];
    }
    return listApplications.map((applications: Partial<ApplicationsEntity>) => {
      return new ApplicationsEntity(applications);
    });
  }
}
export default ApplicationsEntity;
