/* eslint-disable no-unused-vars */
export enum ETwoFactorMethodType {
  AuthenticatorApp = 1,
  RecoveryCode = 2,
}

class TwoFaEntity {
  twoFactorMethodType?: ETwoFactorMethodType;

  createdAt = '';

  id: string = '';

  constructor(users: Partial<TwoFaEntity>) {
    if (!users) {
      return;
    }
    Object.assign(this, users);
  }

  static createListUsers(listUsers: Array<Partial<TwoFaEntity>>) {
    if (!Array.isArray(listUsers)) {
      return [];
    }
    return listUsers.map((users: Partial<TwoFaEntity>) => {
      return new TwoFaEntity(users);
    });
  }
}
export default TwoFaEntity;
