import React from 'react';

import { IRouter } from '@routers/interface';

export const routerViewProfile: IRouter = {
  path: '/profile/:subPageParam',
  name: 'profile.name',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: false,
};

export const routerVerificationInfo: IRouter = {
  path: '/profile/:subPageParam/:verificationId',
  name: 'profile.name.2',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: false,
};
