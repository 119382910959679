/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-vars */

import './style.scss';

import { Modal } from 'antd';
import React from 'react';
import { X } from 'react-feather';

import { isAfterExpiry } from '@helper/functions';
import UsersPresenter from '@modules/users/usersPresenter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import ButtonsAuth from '../ButtonsAuth';
import { EModalConfirmType } from '../Verification/BackupCode';

export interface IModalConfirmAuth {
  isVisible: boolean;
  type?: EModalConfirmType | string;
  title?: string;
  content?: string;
  isInform?: boolean;
}

export interface ModalConfirmAuthProps {
  modal: IModalConfirmAuth;
  handleOnCancel: () => void;
  handleOnOk: (arg?: any) => void;
  setModalExpired?: (arg?: any) => void;
  title: string;
  content: string;
  className?: string;
  isInform?: boolean;
}

const ModalConfirmAuth: React.FC<ModalConfirmAuthProps> = props => {
  const { modal, handleOnCancel, handleOnOk, setModalExpired, className, title, content } = props;
  const { formatMessage } = useAltaIntl();
  const device = useDeviceType();
  const getUsersMeLoginSessionExpiryCall = useSingleAsync(
    UsersPresenter.getUsersMeLoginSessionExpiry,
  );

  const handleCancelModal = () => {
    if (setModalExpired) {
      getUsersMeLoginSessionExpiryCall
        .execute()
        .then((rs: any) => {
          if (isAfterExpiry(rs?.expiryTime)) {
            setModalExpired?.({ isVisible: true });
          } else {
            handleOnCancel();
          }
        })
        .catch(e => console.error(e));
    } else {
      handleOnCancel();
    }
  };

  return (
    <Modal
      centered={device !== ETypeDevice.DESKTOP}
      open={modal.isVisible}
      onCancel={handleCancelModal}
      closeIcon={<X />}
      className={`modal-confirm-auth ${className != null ? className : ''}`}
      footer={<></>}
      destroyOnClose
      maskClosable={false}
    >
      <div className="modal-confirm-auth-content">
        <div className="modal-confirm-auth-content_layout">
          <h1 className="modal-confirm-auth-content_layout_title">
            {formatMessage(`${!modal?.title ? title : modal?.title}`)}
          </h1>
          <p className="modal-confirm-auth-content_layout_text">
            {formatMessage(`${!modal?.content ? content : modal?.content}`)}
          </p>
        </div>
        <ButtonsAuth
          handleCancel={handleCancelModal}
          handleOk={() => handleOnOk(modal?.type)}
          isInform={props?.isInform || modal?.isInform}
        />
      </div>
    </Modal>
  );
};

export default React.memo(ModalConfirmAuth);
