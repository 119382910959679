import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { ChevronLeft, LogOut } from 'react-feather';
import { useSelector } from 'react-redux';

import { LanguageSelector } from '@modules/setting/settingStore';
import { useAltaIntl } from '@shared/hook/useTranslate';
import {
  BackupCodeStep,
  LoginActivityStep,
  ProfilePage,
  ProfileSubPage,
  VerificationStep,
} from '@view/Auth/Profiles';

export interface HeadProfileMobileProps {
  titleMobile: string | undefined;
  handleBack: () => void;
  handleLogout: () => void;
  isLogout: boolean;
  subPageParam?: string;
  isReactNativeWebView: boolean;
  ratio: number;
}

const HeadProfileMobile: React.FC<HeadProfileMobileProps> = props => {
  const { formatMessage } = useAltaIntl();
  const { language } = useSelector(LanguageSelector);

  const _style: any = useMemo(() => {
    const _a: any = {};

    if (props.isReactNativeWebView) {
      _a.height = `calc(10rem * ${props.ratio})`;
      _a.paddingTop = `calc(4rem * ${props.ratio})`;
    }

    return _a;
  }, [props]);

  const _title: string | undefined = useMemo(() => {
    if (props?.subPageParam === ProfilePage.PROFILE_PAGE) {
      return 'profile.head.center.text';
    } else if (props?.subPageParam === ProfileSubPage.ACCOUNT_INFO) {
      return 'profile.menu.accountInfo';
    } else if (props?.subPageParam === ProfileSubPage.SECURITY) {
      return 'profile.menu.security';
    } else if (props?.subPageParam === VerificationStep.STEP_1) {
      return 'profile.verification.page.title';
    } else if (
      props?.subPageParam === VerificationStep.STEP_2 ||
      props?.subPageParam === VerificationStep.STEP_4
    ) {
      return 'profile.verification.add.new.app.title';
    } else if (props?.subPageParam === VerificationStep.STEP_3) {
      return 'profile.verification.app.info.page.title';
    } else if (props?.subPageParam === BackupCodeStep.STEP_1) {
      return 'profile.verification.backup.code.page.title';
    } else if (props?.subPageParam === LoginActivityStep.STEP_1) {
      return 'profile.login.activity.page.title';
    } else if (props?.subPageParam === LoginActivityStep.STEP_2) {
      return props?.titleMobile;
    } else {
      return;
    }
  }, [props?.subPageParam, props?.titleMobile, language]);

  return (
    <div
      className={`profile-page-mobile_head ${props.isReactNativeWebView ? 'native' : ''}`}
      style={_style}
    >
      <div className="profile-page-mobile_head_wrap-back">
        <ChevronLeft onClick={props.handleBack} />
      </div>
      <Typography.Paragraph
        ellipsis={{ rows: 1, tooltip: false }}
        className="profile-page-mobile_head_title"
      >
        {_title != null ? formatMessage(`${_title}`) : ''}
      </Typography.Paragraph>
      <div className="profile-page-mobile_head_wrap-logout">
        {props.isLogout && <LogOut onClick={props.handleLogout} />}
      </div>
    </div>
  );
};

export default React.memo(HeadProfileMobile);
