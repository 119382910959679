/* eslint-disable no-unused-vars */
import React from 'react';

import { useAltaIntl } from '@shared/hook/useTranslate';

import { IListAuthentication } from '../..';
import MethodItem from '../MethodItem';

export interface ListAuthenticationProps {
  listAuthentication: IListAuthentication[];
  handleOnClickItem: (...args: any) => void;
}

const ListAuthentication: React.FC<ListAuthenticationProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <div className="verification-page_layout_bot">
      <h3 className="verification-page_layout_bot_title">
        {formatMessage('profile.verification.page.list.title')}
      </h3>
      <div className="verification-page_layout_bot_list">
        {props.listAuthentication != null &&
          props.listAuthentication?.length > 0 &&
          props.listAuthentication?.map((it: IListAuthentication, index: number) => {
            return <MethodItem key={index} item={it} handleOnClick={props.handleOnClickItem} />;
          })}
      </div>
    </div>
  );
};

export default React.memo(ListAuthentication);
