import { Button, Typography } from 'antd';
import React from 'react';

/* eslint-disable no-unused-vars */
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface HeadContentProps {
  turn: boolean;
  disabledTurn: boolean;
  handleTurn: (arg?: any) => void;
}

const HeadContent: React.FC<HeadContentProps> = props => {
  const { formatMessage, intl } = useAltaIntl();

  return (
    <div className="verification-page_layout_head">
      <div className="verification-page_layout_head_content">
        <Typography.Paragraph className="verification-page_layout_head_content_title">
          {intl.formatMessage(
            { id: 'profile.verification.page.action.title' },
            {
              turn: !props.turn
                ? formatMessage('profile.verification.page.action.btn.off')?.toLocaleLowerCase()
                : formatMessage('profile.verification.page.action.btn.on')?.toLocaleLowerCase(),
            },
          )}
        </Typography.Paragraph>
        <p className="verification-page_layout_head_content_text">
          {formatMessage('profile.verification.page.action.text')}
        </p>
      </div>
      <div
        className={`verification-page_layout_head_action ${props.turn ? '' : 'active'} ${
          props.disabledTurn ? 'disabled' : ''
        }`}
      >
        <Button disabled={props.disabledTurn} onClick={() => props.handleTurn(props.turn)}>
          {props.turn
            ? formatMessage('profile.verification.page.action.btn.off')
            : formatMessage('profile.verification.page.action.btn.on')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(HeadContent);
