export default {
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AccountEmail.NotExists': 'Login information is incorrect',
  'Server.AddBeat.Success': 'Added successfully',
  'Server.DeleteBeat.Success': 'Deleted successfully',
  'Server.UpdateBeat.Success': 'Updated successfully',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Incorrect username or password',
  'Mes.Users.Login.Successfully': 'Login successful',
  'Mes.Classes.Year.MustHave2Characters': 'Academic year must have 2 characters',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Class code must have 2 characters',
  'Mes.Classes.Add.Successfully': 'Class added successfully',
  'Mes.Questions.Update.Successfully': 'Question updated successfully',
  'Mes.Questions.Add.Successfully': 'Question added successfully',
  'Mes.Users.UpdateSuccessfully': 'Account information updated successfully',
  'Mes.Users.Add.Successfully': 'Account added successfully',
  'Mes.Users.DeleteUsers.Successfully': 'Deleted successfully',
  'Mes.Researchs.Update.Successfully': 'Research updated successfully',
  'Mes.Researchs.Add.Successfully': 'Research added successfully',
  'Mes.Researchs.Delete.Successfully': 'Research deleted successfully',
  'server.data.success': 'Success',
  Successful: 'Success',
  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Recovery link has been sent to your email',
  'Mes.User.ResetPasswordSuccessfully': 'Password reset successfully',
  'Mes.Profile.GetDetailSuccessfully': 'Account information retrieved successfully',
  'Mes.Profile.OldPassword.Wrong': 'Old password is incorrect',
  'Mes.Profile.ChangePasswordSuccessfully': 'Password updated successfully',
  'Mes.Role.UpdateSuccessfully': 'Role updated successfully',
  'Mes.Role.CreateSuccessfully': 'Role added successfully',
  'Mes.Role.DeleteSuccessfully': 'Role deleted successfully',
  'Mes.User.Invalid.Username': 'Invalid username',
  'Mes.User.AlreadyExist.Username': 'Username already exists',
  'Mes.User.Required.Username': 'Username is required',
  'Mes.User.NotWhiteSpace.Username': 'Username cannot contain spaces',
  'Mes.User.NotSpecialCharacter.Username':
    'Username cannot contain special characters (except . and @)',
  'Mes.User.Invalid.Email': 'Invalid email',
  'Mes.User.AlreadyExist.Email': 'Email already exists',
  'Mes.User.Required.Email': 'Email is required',
  'Mes.User.NotWhiteSpace.Email': 'Email cannot contain spaces',
  'Mes.User.OverLength.Name': 'User name cannot exceed 250 characters',
  'Mes.User.Invalid.AvatarPicture': 'Invalid avatar image',
  'Mes.User.IsWrong.OldPassword': 'Old password is incorrect',
  'Mes.User.Invalid.OldPassword': 'Invalid old password',
  'Mes.User.Invalid.PhoneNumber': 'Invalid phone number',
  'Mes.User.Required.RoleId': 'Role ID is required',
  'Mes.User.Status.Required': 'Status is required',
  'Mes.User.Status.Invalid': 'Invalid status',
  'Mes.User.Required.RecoveryCode': 'Recovery code is required',
  'Mes.User.NotFound.RecoveryCode': 'User recovery code does not exist',
  'Mes.User.NotFound': 'User not found',
  'Mes.User.PleaseCheckEmail': 'Please check your email',
  'Mes.UserRefreshToken.Required.Token': 'Refresh token is required',
  'Mes.UserRefreshToken.Expired.Token': 'Refresh token has expired',
  'Mes.User.Login.Successfully': 'Login successful',
  'Mes.User.NotFound.Email': 'Email not found',
  'Mes.OAuth.InvalidFormat.RedirectUri': 'Invalid redirect URI',
  'Mes.User.Required.Name': 'User name is required',
  'Mes.Role.Create.Successfully': 'Role created successfully',
  'Mes.Role.Create.Failed': 'Role creation failed',
  'Mes.Role.Update.Successfully': 'Role updated successfully',
  'Mes.Role.Update.Failed': 'Role update failed',
  'Mes.Role.Search.Successfully': 'Role list retrieved successfully',
  'Mes.Role.ViewDetail.Successfully': 'Role details retrieved successfully',
  'Mes.Role.Delete.Successfully': 'Role deleted successfully',
  'Mes.Role.Delete.Failed': 'Role deletion failed',
  'Mes.Role.Required.PermissionCodes': 'Permission codes are required',
  'Mes.Role.OverLength.Code': 'Role code cannot exceed 255 characters',
  'Mes.Role.OverLength.Description': 'Role description cannot exceed 255 characters',
  'Mes.Role.AlreadyExist.Code': 'Role code already exists',
  'Mes.Role.Required.Code': 'Role code is required',
  'Mes.Role.NotWhiteSpace.Code': 'Role code cannot contain spaces',
  'Mes.Role.NotSpecialCharacter.Code':
    'Role code cannot contain special characters (except . and @)',
  'Mes.Role.Required.Name': 'Role name is required',
  'Mes.Role.OverLength.Name': 'Role name cannot exceed 255 characters',
  'Mes.Role.NotFound': 'Role not found',
  'Mes.Role.Repeated.Ids': 'Role ID cannot be repeated',
  'Mes.Role.Required.Ids': 'Role ID is required',
  'Mes.Role.WasUsed': 'Role has already been used',
  'Mes.User.Create.Successfully': 'User created successfully',
  'Mes.User.Create.Failed': 'User creation failed',
  'Mes.User.Update.Successfully': 'User updated successfully',
  'Mes.User.Update.Failed': 'Failed to update user',
  'Mes.User.Search.Successfully': 'User list viewed successfully',
  'Mes.User.ViewDetail.Successfully': 'User details viewed successfully',
  'Mes.User.Delete.Successfully': 'User deleted successfully',
  'Mes.User.Delete.Failed': 'Failed to delete user',
  'Mes.User.Send.Failed': 'Failed to send email to user',
  'Mes.User.CheckRecoveryCode.Successfully': 'Recovery code verified successfully',
  'Mes.User.SendRecoveryCode.Successfully': 'Recovery code sent successfully',
  'Mes.User.ResetPassword.Successfully': 'Password reset successfully',
  'Mes.User.Refresh.Successfully': 'Refreshed successfully',
  'Mes.User.ChangePassword.Successfully': 'Password changed successfully',
  'Mes.User.Profile.Successfully': 'User profile retrieved successfully',
  'Mes.User.Required.TimezoneId': 'Timezone ID is required',
  'Mes.User.Required.NotFound': 'Timezone not found',
  'Mes.User.Required.RedirectUri': 'Redirect URL is required',
  'Mes.User.OverLength.RedirectUri': 'Redirect URL cannot exceed 255 characters',
  'Mes.User.Required.Code': 'Login code is required',
  'Mes.User.OverLength.Code': 'Login code cannot exceed 255 characters',
  'Mes.User.Required.PhoneNumber': 'Phone number is required',
  'Mes.User.OverLength.IdentifierNumber': 'ID number cannot exceed 50 characters',
  'Mes.User.Invalid.IdentifierNumber': 'ID number is invalid',
  'Mes.User.Repeated.Ids': 'User ID must not be repeated',
  'Mes.User.Required.Ids': 'User ID is required',
  'Mes.User.NotAllowed': 'User is not allowed',
  'Mes.SsoResult.Empty.Id': 'SSO user ID not found',
  'Mes.SsoAuthentication.Failed': 'SSO login failed',
  'Mes.SsoResult.Empty.UserName': 'SSO username not found',
  'Messages.SsoAuthentication.Required.AccessToken': 'Access token is required for SSO login',
  'Mes.SsoAuthentication.Invalid.Email': 'SSO confirmed invalid email',
  'Mes.SsoAuthentication.Invalid.PhoneNumber': 'SSO confirmed invalid phone number',
  'Mes.SsoAuthentication.Invalid.Username': 'SSO confirmed invalid username',
  'Mes.SsoAuthentication.Invalid.Groups':
    'Invalid SSO user groups. Please contact the system administrator for assistance!',
  'Mes.SsoAuthentication.Invalid.ClientId':
    'Invalid ClientId. Please contact the system administrator for assistance!',
  'Mes.SsoAuthentication.Invalid.ClientSecret':
    'Invalid ClientSecret. Please contact the system administrator for assistance!',
  'Mes.SsoAuthentication.Invalid.GrantType':
    'Invalid GrantType. Please contact the system administrator for assistance!',
  'Mes.UserMobileDevice.Required.DeviceId': 'Device registration ID is required',
  'Mes.LoginSession.NotFound': 'Login session not found',
  'Mes.LoginSession.Expired': 'Login session has expired',
  'Mes.TwoFa.Invalid.Cache': 'Two-factor authentication cache error',
  'Mes.TwoFa.NotFound': 'Two-factor authentication not found',
  'Mes.TwoFa.NotFound.SecretKey': 'Two-factor authentication secret key not found',
  'Mes.TwoFaMethod.NotAllowed': 'Two-factor authentication method not allowed',
  'Mes.TwoFaMethod.NotFound': 'Two-factor authentication method not found',
  'Mes.UserTwoFaRequest.NotFound.TwoFaMethodType':
    'Two-factor authentication method type not found in request',
  'Mes.UserTwoFaRequest.Required.TwoFaMethodType': 'Two-factor authentication method type required',
  'Mes.User.Invalid.Password': 'Invalid password',
  'Mes.User.Required.Password': 'Password is required',
  'Mes.User.NotAvailable': 'User not available',
  'Mes.User.Expired.SessionId': 'Session ID has expired',
  'Mes.User.Update': 'User update failed',
  'Mes.User.Blocked': 'User account is blocked',
  'Mes.User.Invalid.TOTP': 'Invalid TOTP code',
  'Mes.User.Required.TwoFactor': 'Two-factor authentication required',
  'Mes.User.NotFound.TwoFa': "User's two-factor authentication not found",
  'Mes.User.Invalid.Sid2f': 'Invalid two-factor authentication SID code',
  'Mes.UserRefreshToken.Invalid.Token': 'Invalid refresh token',
  'Mes.UserRefreshToken.WasUsed': 'Refresh token has been used',
  'Mes.TwoFa.Create.Successfully': 'Two-factor authentication method created successfully',
  'Mes.TwoFa.Delete.Successfully': 'Two-factor authentication method deleted successfully',
  'Mes.TwoFa.Update.Successfully': 'Two-factor authentication disabled successfully',
  'Mes.User.WasUsed.RecoveryCode': 'Recovery code has been used',
  'Mes.User.TwoFaAccountVerification.Successfully': 'User authenticated with password successfully',
  'Mes.User.UpdateProfile.Successfully': 'Profile updated successfully',
  'Mes.TwoFaMethod.Search.Successfully':
    "User's two-factor authentication methods retrieved successfully",
  'Mes.LoginSession.Search.Successfully': 'Device login activity retrieved successfully',
  'Mes.LoginSession.Update.Successfully': 'Remote device logout successful',
  'Mes.User.DeleteAvatarProfile.Successfully': 'Avatar deleted successfully',
  'Mes.User.UpdateAvatarProfile.Successfully': 'Avatar updated successfully',
  'Mes.User.Required.Avatar': 'Avatar is required',
  'Mes.User.Invalid.Avatar': 'Invalid avatar',
  'Mes.UserMobileDevice.OverLength.DeviceId': 'Device registration ID cannot exceed 255 characters',
  'Mes.User.NotFound.Username': 'User not found',
  'Mes.User.Required.VerifyCode': 'Verification code is required',
  'Mes.User.NotEnough.RecoveryCode': 'Insufficient recovery code.',
  'Mes.User.RecoveryCode.WasUsed': 'Recovery code has already been used.',
  'Mes.TwoFa.NotMatch.Code': 'The verification code is invalid',
};
