import './style.scss';

import React from 'react';

import { useAltaIntl } from '@shared/hook/useTranslate';

export interface TitleUnderlineProps {
  text: string;
  className?: string;
}

const TitleUnderline: React.FC<TitleUnderlineProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <div className={`title-underline ${!props?.className ? '' : props?.className}`}>
      <h1 className="title-underline_text">{!props.text ? '' : formatMessage(`${props.text}`)}</h1>
      <span className="title-underline_line"></span>
    </div>
  );
};

export default React.memo(TitleUnderline);
