import { Avatar, Dropdown, MenuProps, Typography } from 'antd';
import Cookies from 'js-cookie';
import React from 'react';
import * as Icon from 'react-feather';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { imgAvatar, ImgLogout } from '@assets/images';
import store from '@core/store/redux';
import { RootState } from '@modules';
import ChangeLanguage from '@shared/components/ChangeLanguage';
import { ConfirmReStart } from '@shared/components/ConfirmReStart';
import useClickOutside from '@shared/hook/useClickOutside';
import { useLogout } from '@shared/hook/useLogout';
import { useAltaIntl } from '@shared/hook/useTranslate';
import { ProfilePage } from '@view/Auth/Profiles';
import { routerViewProfile } from '@view/Auth/Profiles/router';

const HeaderComponent = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.profile);
  const refDropdown = React.useRef(null);
  const [isDown, setIsDown] = React.useState<boolean>(false);
  const { formatMessage } = useAltaIntl();
  const logout = useLogout();

  useClickOutside([refDropdown], () => {
    setIsDown(false);
  });

  const itemsDropdown: MenuProps['items'] = React.useMemo(() => {
    return [
      {
        label: (
          <div className="wrap_text">
            <Avatar
              src={user?.avatar || imgAvatar}
              size={'large'}
              className="avatar flex items-center justify-center"
              icon={<UserOutlined />}
            />
            <div className="wrap_right">
              <Typography.Paragraph ellipsis={{ rows: 1 }} className="fullName">
                {user?.fullName}
              </Typography.Paragraph>
              <Typography.Paragraph ellipsis={{ rows: 1 }} className="username">
                {user?.username}
              </Typography.Paragraph>
            </div>
          </div>
        ),
        key: '0',
        onClick: () =>
          navigate(
            generatePath(routerViewProfile.path, { subPageParam: ProfilePage.PROFILE_PAGE }),
          ),
      },
      {
        label: (
          <div className="wrap_text">
            <Avatar src={ImgLogout} className="avatar !border-transparent" />
            <div className="text">{formatMessage('common.logout')}</div>
          </div>
        ),
        key: '2',
        onClick: () => {
          ConfirmReStart({
            okText: formatMessage('common.yes'),
            title: formatMessage('common.logout.title'),
            content: formatMessage('common.logout.content'),
            handleOk: () => {
              logout();
            },
            handleCancel: () => {},
            icon: <LogoutOutlined className="text-white" />,
            modifiers: 'orange',
          });
        },
      },
    ];
  }, [user]);

  return (
    <div className="header-component item-hover__icon intro-x" id="header">
      <ChangeLanguage />
      <div className="dropdown" ref={refDropdown}>
        <Dropdown
          menu={{ items: itemsDropdown }}
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={() => document.getElementById('header') as any}
          className="cursor-pointer"
        >
          <Avatar
            onClick={(e: any) => {
              e.preventDefault();
              setIsDown(!isDown);
            }}
            src={user?.avatar || imgAvatar}
            size={'large'}
            className="w-[4.8rem] h-[4.8rem] flex items-center justify-center"
            icon={<UserOutlined />}
          />
        </Dropdown>
        {!isDown ? (
          <Icon.ChevronDown size={16} className="icon-upDown" />
        ) : (
          <Icon.ChevronUp size={16} className="icon-upDown" />
        )}
      </div>
    </div>
  );
};

export default React.memo(HeaderComponent);
