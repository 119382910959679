import React from 'react';

import Icon from '@ant-design/icons';

const PasswordSvg = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.07812 5.7778V19.2778"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8281 9.5278V12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.97656 11.6006L10.8294 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.0625 14.955L10.8259 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5916 14.955L10.8281 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6809 11.6006L10.8281 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.8281 9.5278V12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.9766 11.6006L19.8294 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0625 14.955L19.8259 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.5916 14.955L19.8281 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.6809 11.6006L19.8281 12.5278"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const PasswordIcon = props => <Icon component={PasswordSvg} {...props} />;
export default PasswordIcon;
