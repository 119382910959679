/* eslint-disable @typescript-eslint/no-unused-expressions */
import './style.scss';

import { Typography } from 'antd';
import React from 'react';
import { ChevronRight } from 'react-feather';

import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface IAccountInfoItem {
  label: string;
  value: string;
  className?: string;
  onClick?: () => void;
}

const AccountInfoItem: React.FC<IAccountInfoItem> = props => {
  const { formatMessage } = useAltaIntl();
  const device = useDeviceType();

  const handleOnClick = () => {
    props?.onClick && props?.onClick();
  };

  return (
    <div
      onClick={handleOnClick}
      className={`account-info-item ${!props?.className ? '' : props?.className}`}
    >
      <div className="account-info-item_content">
        {props.label && (
          <Typography.Paragraph
            ellipsis={{ rows: 1, tooltip: device === ETypeDevice.DESKTOP }}
            className="account-info-item_content_label"
          >
            {formatMessage(props.label)}
          </Typography.Paragraph>
        )}
        {props.value && (
          <Typography.Paragraph
            ellipsis={{ rows: 1, tooltip: device === ETypeDevice.DESKTOP }}
            className="account-info-item_content_value"
          >
            {props.value}
          </Typography.Paragraph>
        )}
      </div>
      {props?.onClick && <ChevronRight />}
    </div>
  );
};

export default React.memo(AccountInfoItem);
