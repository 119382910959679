import { Upload, UploadProps } from 'antd';
import React, { useMemo } from 'react';
import { Edit } from 'react-feather';

import { imgAvatar } from '@shared/assets/images';

import { ETypeAvatar } from '../..';

interface IAvatar {
  typePage: ETypeAvatar;
  propsUpload: UploadProps;
  avatarData?: string;
  fileInfo?: any;
}
const AvatarUser: React.FC<IAvatar> = props => {
  const _avatar = useMemo(() => {
    if (props.typePage === ETypeAvatar.DELETE) {
      return imgAvatar;
    }
    return props?.fileInfo?.url || props?.avatarData || imgAvatar;
  }, [props]);

  return (
    <div className="avatar-user">
      <img alt="avatar" src={_avatar} />
      {props?.fileInfo?.url && (
        <Upload className="avatar-user-upload" {...props.propsUpload}>
          <div className="avatar-user-upload-wrap-icon">
            <Edit />
          </div>
        </Upload>
      )}
    </div>
  );
};

export default React.memo(AvatarUser);
