import './style.scss';

import { Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Trash } from 'react-feather';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import CONFIG from '@config';
import { getRelativeTime, isAfterExpiry } from '@helper/functions';
import authenticationPresenter from '@modules/authentication/presenter';
import { UserSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import { ETwoFaStatus } from '@modules/users/usersEntity';
import UsersPresenter from '@modules/users/usersPresenter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';
import { VerificationStep } from '@view/Auth/Profiles';
import { routerVerificationInfo } from '@view/Auth/Profiles/router';

import { NameAuthApp } from '../';
import ButtonPlus from '../../ButtonPlus';
import ModalConfirmAuth, { IModalConfirmAuth } from '../../ModalConfirmAuth';
import ModalNotifyExpired, { IModalNotifyExpired } from '../../ModalNotifyExpired';
import TitleUnderline from '../../TitleUnderline';

const AppInfo: React.FC = () => {
  const navigate = useNavigate();
  const device = useDeviceType();
  const { formatMessage } = useAltaIntl();
  const [modalConfirmDelete, setModalConfirmDelete] = useState<IModalConfirmAuth>({
    isVisible: false,
  });
  const { language } = useSelector(LanguageSelector);
  const { verificationId } = useParams();
  const [authApp, setAuthApp] = useState<any>(null);
  const [modalExpired, setModalExpired] = useState<IModalNotifyExpired>({ isVisible: false });
  const { user } = useSelector(UserSelector);
  const deleteUsersMeTwoFaCall = useSingleAsync(UsersPresenter.deleteUsersMeTwoFa);
  const getDetailUsersMeTwoFaCall = useSingleAsync(UsersPresenter.getDetailUsersMeTwoFa);
  const getUsersMeLoginSessionExpiryCall = useSingleAsync(
    UsersPresenter.getUsersMeLoginSessionExpiry,
  );

  const isAcceptDelete: boolean = useMemo(() => {
    if (user?.isAvailable || user?.twoFaStatus === ETwoFaStatus.Disabled) {
      return true;
    }
    return false;
  }, [user]);

  const fetchUsersMeLoginSessionExpiry = () => {
    return getUsersMeLoginSessionExpiryCall
      .execute()
      .then(rs => rs)
      .catch(e => Promise.reject(e));
  };

  const handleUpdate = () => {
    if (!verificationId) {
      return;
    }

    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalExpired({ isVisible: true });
        } else {
          navigate(
            generatePath(routerVerificationInfo.path, {
              subPageParam: VerificationStep.STEP_4,
              verificationId: verificationId,
            }),
          );
        }
      })
      .catch(e => console.error(e));
  };

  const handleDelete = () => {
    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalExpired({ isVisible: true });
        } else {
          setModalConfirmDelete({ isVisible: true });
        }
      })
      .catch(e => console.error(e));
  };

  const handleOnCancelConfirmDelete = () => {
    setModalConfirmDelete({ isVisible: false });
  };

  const handleFetchDataDetail = (_id?: string) => {
    getDetailUsersMeTwoFaCall
      .execute(_id)
      .then((rs: any) => {
        setAuthApp(rs?.authenticator);
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          setModalExpired({ isVisible: true });
        }
      });
  };

  const handleDeleteUsersMeTwoFa = (_appId?: string, _id?: string) => {
    deleteUsersMeTwoFaCall
      .execute(_appId, CONFIG.MESSAGE_SESSION_ID_ERROR)
      .then(() => {
        authenticationPresenter.getProfile();
        handleFetchDataDetail(_id);
        setModalConfirmDelete({ isVisible: false });
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          setModalExpired({ isVisible: true });
        }
      });
  };

  const handleOnOkConfirmDelete = () => {
    if (!authApp?.id || !verificationId) {
      return;
    }

    handleDeleteUsersMeTwoFa(authApp?.id, verificationId);
  };

  useEffect(() => {
    if (!verificationId) {
      return;
    }

    handleFetchDataDetail(verificationId);
  }, [verificationId]);

  return (
    <div className="app-info-page">
      <div className="w-full h-full flex flex-row">
        <div className="main-setting">
          {device === ETypeDevice.DESKTOP && (
            <TitleUnderline text="profile.verification.app.info.page.title" />
          )}
          <div className="app-info-page_layout">
            <div className="app-info-page_layout_wrap-text">
              <p className="app-info-page_layout_wrap-text_text">
                {formatMessage('profile.app.info.text-1')}
              </p>
              <p className="app-info-page_layout_wrap-text_text">
                {formatMessage('profile.app.info.text-2')}
              </p>
            </div>
            <h3 className="app-info-page_layout_sub-title">
              {formatMessage('profile.app.info.sub-title')}
            </h3>
            {!authApp ? (
              <ButtonPlus okText={'profile.app.info.btn.plus'} handleOnClick={handleUpdate} />
            ) : (
              <div className="app-info-page_layout_list">
                <div className="app-info-page_layout_list_item">
                  <div className="app-info-page_layout_list_item_left">
                    <div className="app-info-page_layout_list_item_left_title">{NameAuthApp}</div>
                    <div className="app-info-page_layout_list_item_left_text">
                      {getRelativeTime(1, authApp?.createdAt, language)}
                    </div>
                  </div>
                  <div className="app-info-page_layout_list_item_right">
                    <Button className="app-info-page-btn-update" onClick={handleUpdate}>
                      {formatMessage('profile.app.info.btn.update')}
                    </Button>
                    <Button className="app-info-page-btn-delete" onClick={handleDelete}>
                      <Trash />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalConfirmAuth
        modal={modalConfirmDelete}
        handleOnCancel={handleOnCancelConfirmDelete}
        handleOnOk={handleOnOkConfirmDelete}
        setModalExpired={setModalExpired}
        title={
          isAcceptDelete
            ? 'profile.app.info.modal.confirm.title'
            : 'profile.verification.page.delete.inform.title'
        }
        content={
          isAcceptDelete
            ? 'profile.app.info.modal.confirm.content'
            : 'profile.verification.page.delete.inform.content'
        }
        isInform={!isAcceptDelete}
      />
      <ModalNotifyExpired modal={modalExpired} setModal={setModalExpired} />
    </div>
  );
};

export default React.memo(AppInfo);
