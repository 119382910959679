/* eslint-disable max-statements */
/* eslint-disable no-unused-vars */
import './style.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { Lock } from 'react-feather';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { LockIcon, PasswordIcon } from '@assets/icon';
import CONFIG from '@config';
import { getRelativeTime, isAfterExpiry } from '@helper/functions';
import authenticationPresenter from '@modules/authentication/presenter';
import { UserSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import { ETwoFactorMethodType } from '@modules/users/twoFa';
import { ETwoFaStatus } from '@modules/users/usersEntity';
import UsersPresenter from '@modules/users/usersPresenter';
import { ConfirmReStart } from '@shared/components/ConfirmReStart';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import { BackupCodeStep, VerificationStep } from '../..';
import { routerVerificationInfo } from '../../router';
import ModalConfirmAuth, { IModalConfirmAuth } from '../ModalConfirmAuth';
import ModalVerifyAccount, {
  EModalVerifyAccountType,
  IDataModalVerifyAccount,
  IModalVerifyAccount,
} from '../ModalVerifyAccount';
import TitleUnderline from '../TitleUnderline';
import HeadContent from './components/HeadContent';
import ListAuthentication from './components/ListAuthentication';

export const NameAuthApp = 'Google Authenticator';

export enum ETypeListAuthentication {
  APP = 'authentication-app',
  CODE = 'authentication-code',
}

export interface IListAuthentication {
  id?: string;
  type: ETypeListAuthentication;
  icon: any;
  label: string;
  desc1?: string;
  desc2: string;
  device: string;
  isHasDevice: boolean;
  param: string;
  data?: any;
}

const Verification: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage, intl } = useAltaIntl();
  const [turn, setTurn] = useState<boolean>(false);
  const [modalCancelTurn, setModalCancelTurn] = useState<IModalConfirmAuth>({ isVisible: false });
  const device = useDeviceType();
  const { language } = useSelector(LanguageSelector);
  const [listTwoFa, setListTwoFa] = useState<any[]>([]);
  const { user } = useSelector(UserSelector);
  const [modalVeAcc, setModalVeAcc] = useState<IModalVerifyAccount>({ isVisible: false });
  const getListUsersMeTwoFaCall = useSingleAsync(UsersPresenter.getListUsersMeTwoFa);
  const createUsersMeTwoFaCall = useSingleAsync(UsersPresenter.createUsersMeTwoFa);
  const getUsersMeLoginSessionExpiryCall = useSingleAsync(
    UsersPresenter.getUsersMeLoginSessionExpiry,
  );

  const _app = useMemo(() => {
    return listTwoFa?.find(
      (it: any) => it?.twoFactorMethodType === ETwoFactorMethodType.AuthenticatorApp,
    );
  }, [listTwoFa]);

  const _code = useMemo(() => {
    return listTwoFa?.find(
      (it: any) => it?.twoFactorMethodType === ETwoFactorMethodType.RecoveryCode,
    );
  }, [listTwoFa]);

  const disabledTurn: boolean = useMemo(() => {
    if (user?.twoFaStatus === ETwoFaStatus.Enabled) {
      return false;
    }

    const hasNoTwoFaList = !listTwoFa || listTwoFa.length === 0;
    const hasOneValidTwoFa = listTwoFa?.filter((it: any) => it?.twoFa != null)?.length === 1;
    const hasNoRemainingCode = _code?.twoFa?.remainingCode === 0;
    const allTwoFaNull = listTwoFa?.every((it: any) => it?.twoFa == null);

    if (hasNoTwoFaList) {
      return true;
    }

    if (hasOneValidTwoFa && hasNoRemainingCode) {
      return true;
    }

    return allTwoFaNull;
  }, [listTwoFa, _code, user]);

  const listAuthentication: IListAuthentication[] = useMemo(() => {
    if (!_app || !_code) {
      return [];
    }

    const isHasApp = _app?.twoFa != null;
    const isHasCode = _code?.twoFa != null;

    let paramApp = VerificationStep.STEP_2;

    if (isHasApp) {
      paramApp = VerificationStep.STEP_3;
    }

    return [
      {
        id: _app?.id,
        type: ETypeListAuthentication.APP,
        icon: <Lock />,
        label: 'profile.verification.page.list.app.label',
        desc1: isHasApp ? getRelativeTime(1, _app?.twoFa?.createdAt, language) : '',
        desc2: 'profile.verification.page.list.app.desc2',
        device: isHasApp
          ? NameAuthApp
          : formatMessage('profile.verification.page.list.app.no.device'),
        isHasDevice: isHasApp,
        param: paramApp,
      },
      {
        id: _code?.id,
        type: ETypeListAuthentication.CODE,
        icon: <PasswordIcon />,
        label: 'profile.verification.page.list.code.label',
        desc1: isHasCode ? getRelativeTime(1, _code?.twoFa?.createdAt, language) : '',
        desc2:
          _code?.twoFa?.remainingCode != null && _code?.twoFa?.remainingCode === 0
            ? 'profile.verification.page.list.code.desc2.2'
            : 'profile.verification.page.list.code.desc2.1',
        device: isHasCode
          ? intl.formatMessage(
              { id: 'profile.verification.page.list.code.device' },
              { num: Number(_code?.twoFa?.remainingCode) },
            )
          : formatMessage('profile.verification.page.list.code.no.device'),
        isHasDevice: isHasCode && _code?.twoFa?.remainingCode > 0,
        param: BackupCodeStep.STEP_1,
      },
    ];
  }, [language, listTwoFa, _app, _code]);

  const fetchUsersMeLoginSessionExpiry = () => {
    return getUsersMeLoginSessionExpiryCall
      .execute()
      .then(rs => rs)
      .catch(e => Promise.reject(e));
  };

  const goToDetail = (_param?: string, _id?: string) => {
    navigate(
      generatePath(routerVerificationInfo.path, {
        subPageParam: _param || '',
        verificationId: _id || '',
      }),
    );
  };

  const handleOnClick = (_param?: string, _id?: string) => {
    if (!_param || !_id) {
      return;
    }

    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalVeAcc({
            isVisible: true,
            type: EModalVerifyAccountType.VeriListTwoFaGoToDetail,
            data: { param: _param, id: _id },
          });
        } else {
          goToDetail(_param, _id);
        }
      })
      .catch(e => console.error(e));
  };

  const handleCreateTwoFa = (_status?: ETwoFaStatus) => {
    createUsersMeTwoFaCall
      .execute(
        { twoFaStatus: _status },
        _status === ETwoFaStatus.Disabled,
        CONFIG.MESSAGE_SESSION_ID_ERROR,
      )
      .then(() => {
        if (_status === ETwoFaStatus.Enabled) {
          setTurn(true);
          ConfirmReStart({
            title: formatMessage('profile.verification.page.popup.on.title'),
            content: formatMessage('profile.verification.page.popup.on.content'),
            cancelText: formatMessage('common.done'),
            icon: <LockIcon />,
            modifiers: 'orange',
            className: 'handle-turn',
            okButtonProps: {
              className: 'custom-ok-button',
            },
            centered: device !== ETypeDevice.DESKTOP,
          });
        } else if (_status === ETwoFaStatus.Disabled) {
          setTurn(false);
          setModalCancelTurn({ isVisible: false });
        }
        authenticationPresenter.getProfile();
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          if (_status === ETwoFaStatus.Enabled) {
            setModalVeAcc({
              isVisible: true,
              type: EModalVerifyAccountType.VeriListTwoFaTwoFaOn,
              data: { status: _status },
            });
          } else if (_status === ETwoFaStatus.Disabled) {
            setModalVeAcc({
              isVisible: true,
              type: EModalVerifyAccountType.VeriListTwoFaTwoFaOff,
              data: { status: _status },
            });
          }
        }
      });
  };

  const handleDisabled = () => {
    handleCreateTwoFa(ETwoFaStatus.Disabled);
  };

  const handleTurn = (_turn: boolean) => {
    if (_turn) {
      fetchUsersMeLoginSessionExpiry()
        .then((rs: any) => {
          if (isAfterExpiry(rs?.expiryTime)) {
            setModalVeAcc({
              isVisible: true,
              type: EModalVerifyAccountType.VeriListTwoFaTwoFaConfirmOff,
            });
          } else {
            setModalCancelTurn({ isVisible: true });
          }
        })
        .catch(e => console.error(e));
    } else {
      handleCreateTwoFa(ETwoFaStatus.Enabled);
    }
  };

  const handleOnCancelConfirmTurn = () => {
    setModalCancelTurn({ isVisible: false });
  };

  const handleOnOkVeAcc = (_type?: EModalVerifyAccountType, _data?: IDataModalVerifyAccount) => {
    if (!_type) {
      return;
    }

    if (_type === EModalVerifyAccountType.VeriListTwoFaTwoFaConfirmOff) {
      setModalCancelTurn({ isVisible: true });
    } else if (
      _type === EModalVerifyAccountType.VeriListTwoFaTwoFaOn ||
      _type === EModalVerifyAccountType.VeriListTwoFaTwoFaOff
    ) {
      handleCreateTwoFa(_data?.status);
    } else if (_type === EModalVerifyAccountType.VeriListTwoFaGoToDetail) {
      goToDetail(_data?.param, _data?.id);
    }
  };

  useEffect(() => {
    getListUsersMeTwoFaCall
      .execute()
      .then((rs: any) => {
        setListTwoFa([...rs?.data?.twoFaMethods]);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (user?.twoFaStatus === ETwoFaStatus.Disabled) {
      setTurn(false);
    } else if (user?.twoFaStatus === ETwoFaStatus.Enabled) {
      setTurn(true);
    }
  }, [user]);

  return (
    <div className="verification-page">
      <div className="w-full h-full flex flex-row">
        <div className="main-setting">
          {device === ETypeDevice.DESKTOP && (
            <TitleUnderline text="profile.verification.page.title" />
          )}
          <div className="verification-page_layout">
            <HeadContent turn={turn} disabledTurn={disabledTurn} handleTurn={handleTurn} />
            <ListAuthentication
              listAuthentication={listAuthentication}
              handleOnClickItem={handleOnClick}
            />
          </div>
        </div>
      </div>
      <ModalConfirmAuth
        modal={modalCancelTurn}
        handleOnCancel={handleOnCancelConfirmTurn}
        handleOnOk={handleDisabled}
        title={'profile.verification.page.popup.off.title'}
        content={'profile.verification.page.popup.off.content'}
      />
      <ModalVerifyAccount
        modal={modalVeAcc}
        setModal={setModalVeAcc}
        handleOnOk={handleOnOkVeAcc}
      />
    </div>
  );
};

export default React.memo(Verification);
