import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import store from '@core/store/redux';
import { removeProfile } from '@modules/authentication/profileStore';
import { routerLogin } from '@view/Auth/Login/router';

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    store.dispatch(removeProfile());
    Cookies.remove('accessTokenSSO');
    Cookies.remove('refreshTokenSSO');
    Cookies.remove('autoAccess');
    navigate(routerLogin.path);
  };

  return logout;
};
