import React from 'react';

import Icon from '@ant-design/icons';

const LockSvg = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_13932_47418)">
      <path
        d="M34.125 14.4375H7.875C7.15013 14.4375 6.5625 15.0251 6.5625 15.75V34.125C6.5625 34.8499 7.15013 35.4375 7.875 35.4375H34.125C34.8499 35.4375 35.4375 34.8499 35.4375 34.125V15.75C35.4375 15.0251 34.8499 14.4375 34.125 14.4375Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip1_13932_47418)">
        <path
          d="M14.8125 26.125L18.75 30.0625L27.75 21.0625"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M14.4375 14.4375V9.1875C14.4375 7.44702 15.1289 5.77782 16.3596 4.54711C17.5903 3.3164 19.2595 2.625 21 2.625C22.7405 2.625 24.4097 3.3164 25.6404 4.54711C26.8711 5.77782 27.5625 7.44702 27.5625 9.1875V14.4375"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13932_47418">
        <rect width="42" height="42" fill="white" />
      </clipPath>
      <clipPath id="clip1_13932_47418">
        <rect width="18" height="18" fill="white" transform="translate(12 16)" />
      </clipPath>
    </defs>
  </svg>
);
const LockIcon = props => <Icon component={LockSvg} {...props} />;
export default LockIcon;
