/* eslint-disable import/no-extraneous-dependencies */
import '@shared/assets/css/animation.css';
import '@styles/styles.scss';
import 'node_modules/video-react/dist/video-react.css';
import 'react-phone-input-2/lib/style.css';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import React, { memo, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import store from '@core/store/redux';
import locale from '@locale/index';
import { setToken, TokenSelector } from '@modules/authentication/profileStore';
import settingStore, { LanguageSelector } from '@modules/setting/settingStore';
import PrivatePage from '@routers/component/PrivatePage';
import PublicPage from '@routers/component/PublicPage';
import ThemeContext, { ThemeColors } from '@shared/hook/ThemeContext';
import { useQueryParams } from '@shared/hook/useQueryParams';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(duration);

export const initStyle: ThemeColors = {
  colorPrimary: '#F26D21',
  colorPrimaryLight: '#FCE2D3',
  colorPrimaryDark: '#E05200',
  colorPrimaryBg: '#FEF1E9',
  colorPrimaryText: '#E05200',
  colorText: '#333333',
  colorTextSecondary: '#4f4c4d',
  colorLink: '#fff',
  colorBgContainer: '#fff',
  colorBgLayout: '#f5f5f5',
  fontFamily: 'Roboto',
  colorError: 'red',
  colorTextBase: '#000',
  colorTextLightSolid: '#fff',
  colorTextBlue: '#001DB8',
  colorErrorBg: '#ff4d4f',
  colorPrimaryGradient: 'linear-gradient(184.26deg, #FF9960 32.21%, #FF7729 70.97%)',
  colorWhite: '#fff',
};
const MainView = memo(({ statusLogin }: { statusLogin: boolean }) => {
  return <>{statusLogin ? <PrivatePage /> : <PublicPage />}</>;
});

const App: React.FC = () => {
  const token = useSelector(TokenSelector);
  const { language } = useSelector(LanguageSelector);
  const queryParam = useQueryParams();

  useEffect(() => {
    if (token == null || isEmpty(token)) {
      const accessToken = Cookies.get('accessTokenSSO');
      const refreshToken = Cookies.get('refreshTokenSSO');
      if (accessToken == null && refreshToken == null) return;
      if (!isEmpty(accessToken) && !isEmpty(refreshToken)) {
        store.dispatch(setToken({ token: accessToken, refreshToken: refreshToken }));
      }
    }
  }, [token]);

  useEffect(() => {
    switch (queryParam.language) {
      case 'vi':
        store.dispatch(settingStore.actions.updateLanguage('vi'));
        break;
      case 'en':
        store.dispatch(settingStore.actions.updateLanguage('en'));
        break;
      default:
        break;
    }
  }, [queryParam.language]);

  const memoLangData = useMemo(() => {
    return locale[language];
  }, [language, queryParam.language]);

  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ThemeContext token={initStyle}>
        <MainView statusLogin={token !== ''} />
      </ThemeContext>
    </IntlProvider>
  );
};

export default App;
