import './style.scss';

import { Button, Form } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import CONFIG from '@config';
import { isAfterExpiry } from '@helper/functions';
import authenticationPresenter from '@modules/authentication/presenter';
import { LanguageSelector } from '@modules/setting/settingStore';
import { ETwoFactorMethodType } from '@modules/users/twoFa';
import UsersPresenter from '@modules/users/usersPresenter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';
import { VerificationStep } from '@view/Auth/Profiles';
import { routerVerificationInfo } from '@view/Auth/Profiles/router';

import ModalNotifyExpired, { IModalNotifyExpired } from '../../ModalNotifyExpired';
import TitleUnderline from '../../TitleUnderline';
import ListContent from './ListContent';
import ModalAddCode, { IModalAddCode } from './ModalAddCode';
import QRContent from './QRContent';

export interface IContents {
  no?: number;
  label?: string;
  value?: string;
  component?: any;
}

const AddNewApp: React.FC = () => {
  const { formatMessage } = useAltaIntl();
  const device = useDeviceType();
  const { language } = useSelector(LanguageSelector);
  const [modalAddCode, setModalAddCode] = useState<IModalAddCode>({ isVisible: false });
  const [authData, setAuthData] = useState<any>(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [modalExpired, setModalExpired] = useState<IModalNotifyExpired>({ isVisible: false });
  const createUsersMeTwoFaStep1Call = useSingleAsync(UsersPresenter.createUsersMeTwoFaStep1);
  const createUsersMeTwoFaStep2Call = useSingleAsync(UsersPresenter.createUsersMeTwoFaStep2);
  const getUsersMeLoginSessionExpiryCall = useSingleAsync(
    UsersPresenter.getUsersMeLoginSessionExpiry,
  );

  const fetchUsersMeLoginSessionExpiry = () => {
    return getUsersMeLoginSessionExpiryCall
      .execute()
      .then(rs => rs)
      .catch(e => Promise.reject(e));
  };

  const handleOk = () => {
    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalExpired({ isVisible: true });
        } else {
          setModalAddCode({ isVisible: true });
        }
      })
      .catch(e => console.error(e));
  };

  const contents: IContents[] = useMemo(() => {
    const _data: IContents[] = [
      {
        label: 'profile.add.new.app.rule.label.1',
        value: 'profile.add.new.app.rule.value.1',
      },
      {
        label: 'profile.add.new.app.rule.label.2',
        value: 'profile.add.new.app.rule.value.2',
      },
      {
        label: 'profile.add.new.app.rule.label.3',
        value: 'profile.add.new.app.rule.value.3',
      },
    ];

    return _data?.reduce((t: IContents[], it: IContents, index: number) => {
      const _it: IContents = { ...it, no: index };

      if (index === _data?.length - 1) {
        const _component = (
          <QRContent
            valueQR={authData?.authenticator?.qrImageUrl}
            manualKey={authData?.authenticator?.manualKey}
          />
        );
        t.push({ component: _component });
      }

      t.push(_it);
      return t;
    }, []);
  }, [authData, language]);

  const handleOnCancel = () => {
    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalExpired({ isVisible: true });
        } else {
          form.resetFields();
          setModalAddCode({ isVisible: false });
        }
      })
      .catch(e => console.error(e));
  };

  const handleCreateTwoFaStep2 = (_payload?: any, _id?: string) => {
    createUsersMeTwoFaStep2Call
      .execute(_payload, CONFIG.MESSAGE_SESSION_ID_ERROR)
      .then(() => {
        authenticationPresenter.getProfile();
        handleOnCancel();
        navigate(
          generatePath(routerVerificationInfo.path, {
            subPageParam: VerificationStep.STEP_3,
            verificationId: _id || '',
          }),
        );
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          setModalExpired({ isVisible: true });
        }
      });
  };

  useEffect(() => {
    createUsersMeTwoFaStep1Call
      .execute(
        { twoFaMethodType: ETwoFactorMethodType.AuthenticatorApp },
        false,
        CONFIG.MESSAGE_SESSION_ID_ERROR,
      )
      .then((rs: any) => {
        setAuthData(rs);
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          setModalExpired({ isVisible: true });
        }
      });
  }, []);

  return (
    <div className="add-new-app">
      <div className="w-full h-full flex flex-row">
        <div className="main-setting">
          {device === ETypeDevice.DESKTOP && (
            <TitleUnderline text="profile.verification.add.new.app.title" />
          )}
          <div className="add-new-app_layout">
            <ListContent contents={contents} />
            <Button onClick={handleOk}>{formatMessage('common.button.accept')}</Button>
          </div>
        </div>
      </div>
      <ModalAddCode
        form={form}
        modal={modalAddCode}
        setModal={setModalAddCode}
        handleCallApi={handleCreateTwoFaStep2}
        handleOnCancel={handleOnCancel}
      />
      <ModalNotifyExpired modal={modalExpired} setModal={setModalExpired} />
    </div>
  );
};

export default React.memo(AddNewApp);
