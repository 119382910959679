import './style.scss';

/* eslint-disable no-unused-vars */
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Bell } from 'react-feather';

import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useGoToVerificationPage } from '@shared/hook/useGoToVerificationPage';
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface IModalNotifyExpired {
  isVisible: boolean;
}

export interface ModalNotifyExpiredProps {
  modal: IModalNotifyExpired;
  setModal: (args?: any) => void;
  className?: string;
}

const ModalNotifyExpired: React.FC<ModalNotifyExpiredProps> = props => {
  const { modal, setModal, className } = props;
  const [count, setCount] = useState<number>(3);
  const { formatMessage } = useAltaIntl();
  const device = useDeviceType();
  const goToVerificationPage = useGoToVerificationPage();

  const handleCancel = () => {
    setModal({ isVisible: false });
    goToVerificationPage();
  };

  const handleOnClick = () => {
    goToVerificationPage();
  };

  useEffect(() => {
    if (!modal.isVisible) {
      return;
    }
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    } else if (count === 0) {
      goToVerificationPage();
    }
  }, [modal, count]);

  return (
    <Modal
      centered={device !== ETypeDevice.DESKTOP}
      open={modal.isVisible}
      onCancel={handleCancel}
      closable={false}
      className={`modal-notify-expired ${className != null ? className : ''}`}
      footer={<></>}
      destroyOnClose
      maskClosable={false}
    >
      <div className="modal-notify-expired-content">
        <div className="modal-notify-expired-content_icon">
          <Bell />
        </div>
        <div className="modal-notify-expired-content_layout">
          <h1 className="modal-notify-expired-content_layout_title">
            {formatMessage('profile.verification.page.modal.expired.title')}
          </h1>
          <Button className="modal-notify-expired-content_layout_btn" onClick={handleOnClick}>
            <div>{formatMessage('common.close')}</div>
            <div>{`(${count})s`}</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ModalNotifyExpired);
