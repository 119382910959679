import dayjs from 'dayjs';

import PermissionsEntity from '@modules/permissions/entity';

export enum UserType {
  USER_SSO = 1,
  USER_NORMAL = 2,
}

export enum TwoFaStatus {
  Disabled = 1,
  Enabled = 2,
}

class UserProfileEntity {
  username: string = '';

  fullName: string = '';

  email: string = '';

  phoneNumber: string = '';

  idCard: string = '';

  address: string = '';

  avatar: string = '';

  permissions: PermissionsEntity[] = [];

  userType?: number;

  id: string = '';

  createdAt: string = '';

  constructor(userProfile: Partial<UserProfileEntity>) {
    if (!userProfile) {
      return;
    }
    Object.assign(this, userProfile);
    // convert entity type here
    this.createdAt = userProfile.createdAt ? dayjs(userProfile.createdAt).format('DD/MM/YYYY') : '';
  }

  static createListUserProfile(listUserProfile: Array<Partial<UserProfileEntity>>) {
    if (!Array.isArray(listUserProfile)) {
      return [];
    }
    return listUserProfile.map((userProfile: Partial<UserProfileEntity>) => {
      return new UserProfileEntity(userProfile);
    });
  }
}
export default UserProfileEntity;
