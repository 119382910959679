/* eslint-disable no-unused-vars */
import './style.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CONFIG from '@config';
import { exportCodesToTxt, isAfterExpiry } from '@helper/functions';
import authenticationPresenter from '@modules/authentication/presenter';
import { UserSelector } from '@modules/authentication/profileStore';
import { ETwoFactorMethodType } from '@modules/users/twoFa';
import { ETwoFaStatus } from '@modules/users/usersEntity';
import UsersPresenter from '@modules/users/usersPresenter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import ButtonPlus from '../../ButtonPlus';
import ModalConfirmAuth, { IModalConfirmAuth } from '../../ModalConfirmAuth';
import ModalNotifyExpired, { IModalNotifyExpired } from '../../ModalNotifyExpired';
import TitleUnderline from '../../TitleUnderline';
import Actions from './Actions';
import ListCode from './ListCode';

export enum EModalConfirmType {
  ADD = 'add',
  DELETE = 'delete',
}

const BackupCode: React.FC = () => {
  const device = useDeviceType();
  const { formatMessage } = useAltaIntl();
  const { verificationId } = useParams();
  const [modalConfirmAuth, setModalConfirmAuth] = useState<IModalConfirmAuth>({
    isVisible: false,
  });
  const [authCode, setAuthCode] = useState<any>(null);
  const [modalExpired, setModalExpired] = useState<IModalNotifyExpired>({ isVisible: false });
  const { user } = useSelector(UserSelector);
  const createUsersMeTwoFaStep1Call = useSingleAsync(UsersPresenter.createUsersMeTwoFaStep1);
  const getDetailUsersMeTwoFaCall = useSingleAsync(UsersPresenter.getDetailUsersMeTwoFa);
  const deleteUsersMeTwoFaCall = useSingleAsync(UsersPresenter.deleteUsersMeTwoFa);
  const getUsersMeLoginSessionExpiryCall = useSingleAsync(
    UsersPresenter.getUsersMeLoginSessionExpiry,
  );

  const isAcceptDelete: boolean = useMemo(() => {
    const isOutOfCode =
      authCode?.recoveryCodes?.filter((it: any) => it?.isUsed === false)?.length === 0;

    if (isOutOfCode) {
      return true;
    }

    if (user?.isAvailable || user?.twoFaStatus === ETwoFaStatus.Disabled) {
      return true;
    }
    return false;
  }, [user, authCode]);

  const fetchUsersMeLoginSessionExpiry = () => {
    return getUsersMeLoginSessionExpiryCall
      .execute()
      .then(rs => rs)
      .catch(e => Promise.reject(e));
  };

  const fetchDetailUsersMeTwoFa = (_id?: string) => {
    return getDetailUsersMeTwoFaCall
      .execute(_id)
      .then(rs => rs)
      .catch(e => Promise.reject(e));
  };

  const handleFetchDataDetail = (_id?: string) => {
    fetchDetailUsersMeTwoFa(_id)
      .then((rs: any) => {
        setAuthCode(rs?.recoveryCode);
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          setModalExpired({ isVisible: true });
        }
      });
  };

  const handleDownload = (_authCode: any, _id?: string) => {
    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalExpired({ isVisible: true });
        } else {
          fetchDetailUsersMeTwoFa(_id)
            .then((res: any) => {
              const _data: string[] | undefined = res?.recoveryCode?.recoveryCodes
                ?.filter((i: any) => i?.isUsed === false)
                ?.map((it: any) => it?.code);
              exportCodesToTxt(_data);
            })
            .catch(e => {
              console.error(e);
              if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
                setModalExpired({ isVisible: true });
              }
            });
        }
      })
      .catch(e => console.error(e));
  };

  const handleAddNew = () => {
    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalExpired({ isVisible: true });
        } else {
          setModalConfirmAuth({
            isVisible: true,
            type: EModalConfirmType.ADD,
            title: 'profile.app.info.backup-code.modal.add.title',
            content: 'profile.app.info.backup-code.modal.add.content',
          });
        }
      })
      .catch(e => console.error(e));
  };

  const handleDelete = () => {
    fetchUsersMeLoginSessionExpiry()
      .then((rs: any) => {
        if (isAfterExpiry(rs?.expiryTime)) {
          setModalExpired({ isVisible: true });
        } else {
          if (isAcceptDelete) {
            setModalConfirmAuth({ isVisible: true, type: EModalConfirmType.DELETE });
          } else {
            setModalConfirmAuth({
              isVisible: true,
              type: EModalConfirmType.DELETE,
              title: 'profile.verification.page.delete.inform.title',
              content: 'profile.verification.page.delete.inform.content',
              isInform: true,
            });
          }
        }
      })
      .catch(e => console.error(e));
  };

  const handleOnCancelConfirmTurnDelete = () => {
    setModalConfirmAuth({ isVisible: false });
  };

  const handleDeleteUsersMeTwoFa = (_codeId?: string, _id?: string) => {
    deleteUsersMeTwoFaCall
      .execute(_codeId, CONFIG.MESSAGE_SESSION_ID_ERROR)
      .then(() => {
        authenticationPresenter.getProfile();
        handleFetchDataDetail(_id);
        setModalConfirmAuth({ isVisible: false });
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          setModalExpired({ isVisible: true });
        }
      });
  };

  const handleCreateTwoFaStep1 = () => {
    createUsersMeTwoFaStep1Call
      .execute(
        { twoFaMethodType: ETwoFactorMethodType.RecoveryCode },
        true,
        CONFIG.MESSAGE_SESSION_ID_ERROR,
      )
      .then((rs: any) => {
        authenticationPresenter.getProfile();
        setAuthCode(rs?.recoveryCode);
        setModalConfirmAuth({ isVisible: false });
      })
      .catch(e => {
        console.error(e);
        if (e?.response?.data?.message === CONFIG.MESSAGE_SESSION_ID_ERROR) {
          setModalExpired({ isVisible: true });
        }
      });
  };

  const handleOnOkConfirmTurnDelete = (_type: EModalConfirmType) => {
    if (_type === EModalConfirmType.DELETE) {
      if (!authCode?.id || !verificationId) {
        return;
      }

      handleDeleteUsersMeTwoFa(authCode?.id, verificationId);
    } else if (_type === EModalConfirmType.ADD) {
      handleCreateTwoFaStep1();
    }
  };

  useEffect(() => {
    if (!verificationId) {
      return;
    }

    handleFetchDataDetail(verificationId);
  }, [verificationId]);

  return (
    <div className="backup-code">
      <div className="w-full h-full flex flex-row">
        <div className="main-setting">
          {device === ETypeDevice.DESKTOP && (
            <TitleUnderline text="profile.verification.backup.code.page.title" />
          )}
          <div className="backup-code_layout">
            <p className="backup-code_layout_text">
              {formatMessage('profile.app.info.backup-code.text')}
            </p>
            <h3 className={`backup-code_layout_sub-title ${!authCode ? '' : 'more-mar'}`}>
              {formatMessage('profile.app.info.backup-code.sub-title')}
            </h3>
            {!authCode ? (
              <ButtonPlus
                okText={'profile.app.info.backup-code.action.btn.plus'}
                handleOnClick={handleAddNew}
              />
            ) : (
              <div className="backup-code_layout_main">
                <Actions
                  authCode={authCode}
                  verificationId={verificationId}
                  handleDownload={handleDownload}
                  handleAddNew={handleAddNew}
                  handleDelete={handleDelete}
                />
                <ListCode authCode={authCode} />
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalConfirmAuth
        modal={modalConfirmAuth}
        handleOnCancel={handleOnCancelConfirmTurnDelete}
        handleOnOk={handleOnOkConfirmTurnDelete}
        setModalExpired={setModalExpired}
        title={'profile.app.info.backup-code.modal.delete.title'}
        content={'profile.app.info.backup-code.modal.delete.content'}
      />
      <ModalNotifyExpired modal={modalExpired} setModal={setModalExpired} />
    </div>
  );
};

export default React.memo(BackupCode);
