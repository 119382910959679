/* eslint-disable no-unused-vars */
import './style.scss';

import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';

import { UserSelector } from '@modules/authentication/profileStore';
import { ETwoFactorMethodType } from '@modules/users/twoFa';
import { ETwoFaStatus } from '@modules/users/usersEntity';
import UsersPresenter from '@modules/users/usersPresenter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import ButtonsAuth from '../ButtonsAuth';

export enum EModalVerifyAccountType {
  VeriListTwoFaTwoFaOn = 'VeriListTwoFaTwoFaOn',
  VeriListTwoFaTwoFaOff = 'VeriListTwoFaTwoFaOff',
  VeriListTwoFaTwoFaConfirmOff = 'VeriListTwoFaTwoFaConfirmOff',
  VeriListTwoFaGoToDetail = 'VeriListTwoFaGoToDetail',
}

export interface IDataModalVerifyAccount {
  param?: string;
  id?: string;
  type?: ETwoFactorMethodType | string;
  status?: ETwoFaStatus | number;
  appId?: string;
  codeId?: string;
  payload?: any;
}

export interface IModalVerifyAccount {
  isVisible: boolean;
  type?: EModalVerifyAccountType;
  data?: IDataModalVerifyAccount;
}

export interface ModalVerifyAccountProps {
  modal: IModalVerifyAccount;
  setModal: (args?: any) => void;
  handleOnCancel?: () => void;
  handleOnOk?: (...args: any) => void;
  className?: string;
}

const ModalVerifyAccount: React.FC<ModalVerifyAccountProps> = props => {
  const { modal, handleOnCancel, handleOnOk, className, setModal } = props;
  const { formatMessage, intl } = useAltaIntl();
  const device = useDeviceType();
  const [form] = useForm();
  const { user } = useSelector(UserSelector);
  const usersVerificationCall = useSingleAsync(UsersPresenter.usersVerification);

  const handleCancelModal = () => {
    form.resetFields();
    setModal({ isVisible: false });
    handleOnCancel?.();
  };

  const handleOnOkModal = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    usersVerificationCall
      .execute(values)
      .then(() => {
        handleOnOk?.(modal?.type, modal?.data);
        handleCancelModal();
      })
      .catch(e => console.error(e));
  };

  return (
    <Modal
      centered={device !== ETypeDevice.DESKTOP}
      open={modal.isVisible}
      onCancel={handleCancelModal}
      closeIcon={<X />}
      className={`modal-verify-acc ${className != null ? className : ''}`}
      footer={<></>}
      destroyOnClose
      maskClosable={false}
    >
      <div className="modal-verify-acc-content">
        <div className="modal-verify-acc-content_layout">
          <h1 className="modal-verify-acc-content_layout_title">
            {formatMessage('profile.modal.verify.account.title')}
          </h1>
          <p className="modal-verify-acc-content_layout_text">
            {intl.formatMessage(
              { id: 'profile.modal.verify.account.content' },
              {
                name: <span className="text-active">{user?.username}</span>,
              },
            )}
          </p>
        </div>
        <Form
          className="main-form"
          layout="vertical"
          name="modal-verify-acc"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item
            label={formatMessage('profile.modal.verify.account.pw.pl')}
            name="password"
            rules={[
              {
                required: true,
                message: formatMessage('profile.modal.verify.account.pw.required.1'),
              },
              {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                message: formatMessage('profile.modal.verify.account.pw.required.2'),
              },
            ]}
          >
            <Input.Password placeholder={formatMessage('profile.modal.verify.account.pw.pl')} />
          </Form.Item>
        </Form>
        <ButtonsAuth handleCancel={handleCancelModal} handleOk={handleOnOkModal} />
      </div>
    </Modal>
  );
};

export default React.memo(ModalVerifyAccount);
