import { Typography } from 'antd';
/* eslint-disable no-unused-vars */
import React from 'react';
import { ChevronRight } from 'react-feather';

import { useAltaIntl } from '@shared/hook/useTranslate';

import { IListAuthentication } from '../..';

export interface MethodItemProps {
  item: IListAuthentication;
  handleOnClick: (...args: any) => void;
}

const MethodItem: React.FC<MethodItemProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <div
      onClick={() => props.handleOnClick(props.item.param, props.item?.id)}
      className="verification-page_layout_bot_list_item"
    >
      <div className="verification-page_layout_bot_list_item_left">
        <div className="verification-page_layout_bot_list_item_left_wrap">
          <div className="verification-page_layout_bot_list_item_left_wrap_wrap-top">
            {props.item.icon}
            <Typography.Paragraph
              ellipsis={{ rows: 1, tooltip: false }}
              className="verification-page_layout_bot_list_item_left_wrap_wrap-top_text"
            >
              {formatMessage(`${props.item.label}`)}
            </Typography.Paragraph>
          </div>
          <div
            className={`verification-page_layout_bot_list_item_left_wrap_bot ${
              props.item.isHasDevice ? 'active' : ''
            }`}
          >
            {props.item.isHasDevice ? props.item.desc1 : formatMessage(`${props.item.desc2}`)}
          </div>
        </div>
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          className="verification-page_layout_bot_list_item_left_device"
        >
          {props.item.device}
        </Typography.Paragraph>
      </div>
      <div className="verification-page_layout_bot_list_item_right">
        <ChevronRight />
      </div>
    </div>
  );
};

export default React.memo(MethodItem);
