import React from 'react';

export interface ListCodeProps {
  authCode?: any;
}

const ListCode: React.FC<ListCodeProps> = props => {
  return (
    <div className="backup-code_layout_main_list">
      {props?.authCode?.recoveryCodes?.map((it: any, index: number) => {
        const _disabled: boolean = it?.isUsed === true;
        return (
          <div
            key={index}
            className={`backup-code_layout_main_list_item ${_disabled ? 'item-disabled' : ''}`}
          >
            {it?.code}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(ListCode);
