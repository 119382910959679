import React from 'react';

import Icon from '@ant-design/icons';

const CopySvg = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14047_4433)">
      <path
        d="M15.75 16.25H20.25V4.25H8.25V8.75"
        stroke="#F26D21"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.75 8.75H3.75V20.75H15.75V8.75Z"
        stroke="#F26D21"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14047_4433">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
const CopyIcon = props => <Icon component={CopySvg} {...props} />;
export default CopyIcon;
