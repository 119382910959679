import React from 'react';

import { useAltaIntl } from '@shared/hook/useTranslate';

import { IContents } from '../';

export interface ListContentProps {
  contents: IContents[];
}

const ListContent: React.FC<ListContentProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <div className="add-new-app_layout_content">
      <div className="add-new-app_layout_content_list">
        {props.contents?.map((it: IContents, index: number) => {
          if (it?.component != null) {
            return (
              <div key={index} className="add-new-app_layout_content_list_qr">
                {it?.component}
              </div>
            );
          }
          return (
            <div key={index} className="add-new-app_layout_content_list_item">
              <div className="add-new-app_layout_content_list_item_no">{`${
                Number(it?.no) + 1
              }.`}</div>
              <div className="add-new-app_layout_content_list_item_wrap">
                <div className="add-new-app_layout_content_list_item_wrap_label">
                  {formatMessage(`${it?.label}`)}
                </div>
                <p className="add-new-app_layout_content_list_item_wrap_value">
                  {formatMessage(`${it?.value}`)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(ListContent);
