import './style.scss';

import { Typography } from 'antd';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { generatePath, useNavigate } from 'react-router-dom';

import { ETypeDevice } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

import { ISubPage } from '../..';
import { routerViewProfile } from '../../router';
import TitleUnderline from '../TitleUnderline';

export interface SettingsSideBarProps {
  subPages: ISubPage[];
  subPageParam?: string;
  device?: string;
}

const SettingsSideBar: React.FC<SettingsSideBarProps> = props => {
  const navigate = useNavigate();
  const { formatMessage } = useAltaIntl();

  const handleOnClick = (_param: string) => {
    navigate(generatePath(routerViewProfile.path, { subPageParam: _param }));
  };

  return (
    <div className="settings-side-bar">
      <div className="settings-side-bar_head">
        <TitleUnderline className="settings-side-bar-title" text="profile.menu.title" />
      </div>
      {props?.subPages != null && props?.subPages?.length > 0 && (
        <ul className="settings-side-bar_menu">
          {props?.subPages?.map((it: ISubPage, index) => {
            const _isActive: boolean = it.param === props?.subPageParam;

            return (
              <li
                key={index}
                className={`settings-side-bar_menu_item ${_isActive ? 'active' : ''}`}
                onClick={() => handleOnClick(it.param)}
              >
                {it.icon}
                <Typography.Paragraph
                  ellipsis={{ rows: 1, tooltip: false }}
                  className="settings-side-bar_menu_item_text"
                >
                  {formatMessage(`${it.name}`)}
                </Typography.Paragraph>
                {props.device !== ETypeDevice.DESKTOP && (
                  <ChevronRight className="icon-right-mobile" />
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default React.memo(SettingsSideBar);
