import dayjs from 'dayjs';

export enum TwoFasType {
  Recovery_Code = 2,
  Authenticator = 1,
}

class TwoFasEntity {
  //copy props from backend:
  createdAt = '';

  disabledAt?: string;

  id?: string;

  isEnabled?: boolean;

  type?: TwoFasType;

  updatedAt?: string;

  isAvailable?: boolean;

  constructor(twoFas: Partial<TwoFasEntity>) {
    if (!twoFas) {
      return;
    }
    Object.assign(this, twoFas);
    // convert entity type here
    this.createdAt = twoFas.createdAt ? dayjs(twoFas.createdAt).format('DD/MM/YYYY') : '';
  }

  static createListTwoFas(listTwoFas: Array<Partial<TwoFasEntity>>) {
    if (!Array.isArray(listTwoFas)) {
      return [];
    }
    return listTwoFas.map((twoFas: Partial<TwoFasEntity>) => {
      return new TwoFasEntity(twoFas);
    });
  }
}
export default TwoFasEntity;
