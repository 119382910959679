/* eslint-disable import/no-extraneous-dependencies */
import './style.scss';

import { message, Typography } from 'antd';
import React, { useMemo } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';

import { CopyIcon } from '@assets/icon';
import { LanguageSelector } from '@modules/setting/settingStore';
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface QRContentProps {
  valueQR?: string;
  manualKey?: string;
}

const QRContent: React.FC<QRContentProps> = props => {
  const { formatMessage } = useAltaIntl();
  const { language } = useSelector(LanguageSelector);

  const handleCopy = (textToCopy: string) => {
    const tempInput = document.createElement('input');
    tempInput.value = textToCopy;

    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand('copy');

    document.body.removeChild(tempInput);

    message.success(formatMessage('profile.add.new.app.qr.code.copy.success'));
  };

  const TextContent: React.ReactNode = useMemo(() => {
    return (
      <div className="qr-content_wrap-qr_text">
        {formatMessage('profile.add.new.app.qr.code.text')}
      </div>
    );
  }, [language]);

  return (
    <div className="qr-content">
      <div className="qr-content_wrap-qr">
        <div className="qr-content_wrap-qr_qr">
          <QRCode
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={props?.valueQR || ''}
          />
        </div>
        {TextContent}
      </div>
      <div className="qr-content_code">
        <div className="qr-content_code_wrap">
          <Typography.Paragraph
            ellipsis={{ rows: 2, tooltip: true }}
            className="qr-content_code_wrap_value"
          >
            {props?.manualKey || ''}
          </Typography.Paragraph>
        </div>
        <div className="qr-content_code_action" onClick={() => handleCopy(props?.manualKey || '')}>
          <div className="qr-content_code_action_text">
            {formatMessage('profile.add.new.app.qr.code.copy.text')}
          </div>
          <CopyIcon />
        </div>
      </div>
    </div>
  );
};

export default React.memo(QRContent);
