/* eslint-disable no-unused-vars */

export enum ELoggedInStatus {
  LoggedOut = 1,
  Active = 2,
}

class UserDeviceLoginEntity {
  userAgent?: string;

  platform?: string;

  ipAddress?: string;

  loggedInStatus?: ELoggedInStatus;

  loginSessionId?: string;

  location?: string;

  createdAt = '';

  id: string = '';

  constructor(users: Partial<UserDeviceLoginEntity>) {
    if (!users) {
      return;
    }
    Object.assign(this, users);
  }

  static createListUsers(listUsers: Array<Partial<UserDeviceLoginEntity>>) {
    if (!Array.isArray(listUsers)) {
      return [];
    }
    return listUsers.map((users: Partial<UserDeviceLoginEntity>) => {
      return new UserDeviceLoginEntity(users);
    });
  }
}
export default UserDeviceLoginEntity;
