/* eslint-disable no-unused-vars */
import { Typography } from 'antd';
import React from 'react';
import { ChevronRight } from 'react-feather';

import { useAltaIntl } from '@shared/hook/useTranslate';

import { IListFeature } from '../..';

export interface FeatureItemProps {
  item: IListFeature;
  handleOnClick: (arg?: any) => void;
}

const FeatureItem: React.FC<FeatureItemProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <div
      onClick={() => props.handleOnClick(props.item.action)}
      className="security-page_layout_bot_item"
    >
      <div className="security-page_layout_bot_item_left">
        <div className="security-page_layout_bot_item_left_wrap">
          {props.item.icon}
          <Typography.Paragraph
            ellipsis={{ rows: 1, tooltip: false }}
            className="security-page_layout_bot_item_left_wrap_title"
          >
            {formatMessage(props.item.title)}
          </Typography.Paragraph>
        </div>
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          className="security-page_layout_bot_item_left_text"
        >
          {props.item?.text}
        </Typography.Paragraph>
      </div>
      <div className="security-page_layout_bot_item_right">
        <ChevronRight />
      </div>
    </div>
  );
};

export default React.memo(FeatureItem);
