import './style.scss';

import { Typography } from 'antd';
import React from 'react';
import { Camera } from 'react-feather';

import { imgAvatar } from '@assets/images';

export interface IAccountAvatar {
  name: string;
  imageUrl: string;
  onClick: () => void;
}

const AccountAvatar = ({ name, imageUrl, onClick }: IAccountAvatar) => {
  return (
    <div className="account-info-avatar">
      <div className="account-info-wrap-img" onClick={onClick}>
        <img src={imageUrl || imgAvatar} alt="avatar" />
        <div className="account-info-avatar-icon">
          <Camera />
        </div>
      </div>
      <Typography.Paragraph ellipsis={{ rows: 1, tooltip: true }} className="account-info-name">
        {name}
      </Typography.Paragraph>
    </div>
  );
};

export default React.memo(AccountAvatar);
