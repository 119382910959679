import React from 'react';

import Icon from '@ant-design/icons';

const svgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_14030_13063)">
      <path
        d="M10 4.5L17.5 12L10 19.5"
        stroke="#F26D21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14030_13063">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChevronRightIcon = props => <Icon component={svgIcon} {...props} />;
export default ChevronRightIcon;
