import './style.scss';

/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { getRelativeTime, getSid, sendLogOut } from '@helper/functions';
import { UserSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import { ELoggedInStatus } from '@modules/users/userDeviceLoginEntity';
import UsersPresenter from '@modules/users/usersPresenter';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useLogout } from '@shared/hook/useLogout';
import { useAltaIntl } from '@shared/hook/useTranslate';
import { LoginActivityStep } from '@view/Auth/Profiles';
import { routerViewProfile } from '@view/Auth/Profiles/router';

import ModalConfirmAuth, { IModalConfirmAuth } from '../../ModalConfirmAuth';
import TitleUnderline from '../../TitleUnderline';
import ActivityCard from '../ActivityCard';

export interface DeviceDetailProps {
  setTitleMobile: (arg?: any) => void;
}

const DeviceDetail: React.FC<DeviceDetailProps> = props => {
  const device = useDeviceType();
  const { verificationId } = useParams();
  const { formatMessage } = useAltaIntl();
  const { language } = useSelector(LanguageSelector);
  const [dataDetail, setDataDetail] = useState<any>(null);
  const navigate = useNavigate();

  const { user } = useSelector(UserSelector);
  const sid = useMemo(() => {
    return getSid(user?.username);
  }, [user]);

  const logout = useLogout();
  const [modalConfirmLogout, setModalConfirmLogout] = useState<IModalConfirmAuth>({
    isVisible: false,
  });
  const getListUsersLoginSessionCall = useSingleAsync(UsersPresenter.getListUsersLoginSession);
  const logoutDevicesCall = useSingleAsync(UsersPresenter.logoutDevices);

  const fetchDataLoginSession = (_verificationId?: string) => {
    return getListUsersLoginSessionCall
      .execute(
        { pageSize: 1 },
        {
          filter: {
            loginSessionId: { op: '$eq', value: _verificationId },
            loggedInStatus: { op: '$eq', value: ELoggedInStatus.Active },
          },
        },
      )
      .then((rs: any) => {
        return rs;
      })
      .catch(e => Promise.reject(e));
  };

  const freshData = (_verificationId?: string) => {
    if (!_verificationId) {
      return;
    }

    fetchDataLoginSession(_verificationId)
      .then((rs: any) => {
        if (!rs) {
          return;
        }
        let _data: any = null;

        if (rs?.data?.length === 1) {
          _data = {};
          _data.primary = rs?.data?.[0];
          _data.first = rs?.moreInfo?.firstTimeLogin;
        }

        props.setTitleMobile(rs?.data?.[0]?.deviceName || '');
        setDataDetail(_data);
      })
      .catch(e => console.error(e));
  };

  const handleLogoutDetail = () => {
    setModalConfirmLogout({ isVisible: true });
  };

  const handleOnCancelConfirmAuth = () => {
    setModalConfirmLogout({ isVisible: false });
  };

  const handleOnOkConfirmAuth = () => {
    logoutDevicesCall
      .execute([verificationId])
      .then(() => {
        if (verificationId === sid) {
          sendLogOut();
          logout();
        } else {
          navigate(
            generatePath(routerViewProfile.path, { subPageParam: LoginActivityStep.STEP_1 }),
          );
        }
      })
      .catch(e => console.error(e));
  };

  useEffect(() => {
    freshData(verificationId);
  }, [verificationId]);

  return (
    <div className="login-activities-detail">
      <div className="w-full h-full flex flex-row">
        <div className="main-setting">
          {device === ETypeDevice.DESKTOP && (
            <TitleUnderline text={dataDetail?.primary?.deviceName} />
          )}
          <div className="login-activities-detail_layout">
            <div className="login-activities-detail_layout_head">
              <h3 className="login-activities-detail_layout_head_title">
                {formatMessage('profile.login-activities.detail.title.1')}
              </h3>
              <ActivityCard
                key={dataDetail?.primary?.loginSessionId}
                id={dataDetail?.primary?.loginSessionId}
                isPrimary={true}
                isDetailPage={true}
                name={dataDetail?.primary?.location}
                text={getRelativeTime(6, dataDetail?.primary?.createdAt, language)}
                descActive={true}
                handleLogoutDetail={handleLogoutDetail}
              />
            </div>
            <div className="login-activities-detail_layout_head">
              <h3 className="login-activities-detail_layout_head_title">
                {formatMessage('profile.login-activities.detail.title.2')}
              </h3>
              <ActivityCard
                key={dataDetail?.first?.loginSessionId}
                id={dataDetail?.first?.loginSessionId}
                isDetailPage={true}
                name={dataDetail?.first?.location}
                text={getRelativeTime(6, dataDetail?.first?.createdAt, language)}
                descActive={true}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmAuth
        modal={modalConfirmLogout}
        handleOnCancel={handleOnCancelConfirmAuth}
        handleOnOk={handleOnOkConfirmAuth}
        title={'profile.login-activities.detail.modal.confirm.title'}
        content={'profile.login-activities.detail.modal.confirm.content'}
      />
    </div>
  );
};

export default React.memo(DeviceDetail);
