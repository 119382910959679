export default {
  'server.notFound': 'Không tồn tại',
  'server.networkError': 'Lỗi mạng',
  'Server.AccountEmail.NotExists': 'Thông tin đăng nhập không đúng',
  'Server.AddBeat.Success': 'Thêm thành công',
  'Server.DeleteBeat.Success': 'Xóa thành công',
  'Server.UpdateBeat.Success': 'Cập nhập thành công',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Sai tên đăng nhập hoặc mật khẩu',
  'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  'Mes.Classes.Year.MustHave2Characters': 'Năm học phải có 2 kí tự',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Mã lớp học phải có 2 kí tự',
  'Mes.Classes.Add.Successfully': 'Thêm lớp học thành công',
  'Mes.Questions.Update.Successfully': 'Cập nhật câu hỏi thành công',
  'Mes.Questions.Add.Successfully': 'Thêm câu hỏi thành công',
  'Mes.Users.UpdateSuccessfully': 'Cập nhật thông tin tài khoản thành công',
  'Mes.Users.Add.Successfully': 'Thêm tài khoản thành công',
  'Mes.Users.DeleteUsers.Successfully': 'Xóa thành công',
  'Mes.Researchs.Update.Successfully': 'Cập nhật bài nghiên cứu thành công',
  'Mes.Researchs.Add.Successfully': 'Thêm bài nghiên cứu thành công',
  'Mes.Researchs.Delete.Successfully': 'Xóa bài nghiên cứu thành công',
  'server.data.success': 'Thành công',
  Successful: 'Thành công',
  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Link khôi phục đã được gửi tới email của bạn',
  'Mes.User.ResetPasswordSuccessfully': 'Đặt lại mật khẩu thành công',
  'Mes.Profile.GetDetailSuccessfully': 'Lấy thông tin tài khoản thành công',
  'Mes.Profile.OldPassword.Wrong': 'Mật khẩu cũ không đúng',
  'Mes.Profile.ChangePasswordSuccessfully': 'Cập nhật mật khẩu thành công',
  'Mes.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Role.CreateSuccessfully': 'Thêm vai trò thành công',
  'Mes.Role.DeleteSuccessfully': 'Xóa vai trò thành công',
  'Mes.User.Invalid.Username': 'Tên đăng nhập không hợp lệ',
  'Mes.User.AlreadyExist.Username': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Required.Username': 'Tên đăng nhập là bắt buộc',
  'Mes.User.NotWhiteSpace.Username': 'Tên đăng nhập không có khoảng trắng',
  'Mes.User.NotSpecialCharacter.Username': 'Tên đăng nhập không chứa ký tự đặc biệt (trừ dấu . @)',
  'Mes.User.Invalid.Email': 'Email không hợp lệ',
  'Mes.User.AlreadyExist.Email': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Required.Email': 'Email là bắt buộc',
  'Mes.User.NotWhiteSpace.Email': 'Email không có khoảng trắng',
  'Mes.User.OverLength.Name': 'Tên người dùng không vượt quá 250 kí tự',
  'Mes.User.Invalid.AvatarPicture': 'Ảnh đại diện không hợp lệ',
  'Mes.User.IsWrong.OldPassword': 'Mật khẩu cũ không đúng',
  'Mes.User.Invalid.OldPassword': 'Mật khẩu cũ không hợp lệ',
  'Mes.User.Invalid.PhoneNumber': 'Số điện thoại không hợp lệ',
  'Mes.User.Required.RoleId': 'Id vai trò là bắt buộc',
  'Mes.User.Status.Required': 'Trạng thái là bắt buộc',
  'Mes.User.Status.Invalid': 'Trạng thái không hợp lệ',
  'Mes.User.Required.RecoveryCode': 'Mã khôi phục là bắt buộc',
  'Mes.User.NotFound.RecoveryCode': 'Mã khôi phục người dùng không tồn tại',
  'Mes.User.NotFound': 'Không tìm thấy người dùng',
  'Mes.User.PleaseCheckEmail': 'Hãy kiểm tra lại email của bạn',
  'Mes.UserRefreshToken.Required.Token': 'Mã làm mới là bắt buộc',
  'Mes.UserRefreshToken.Expired.Token': 'Mã làm mới đã hết hạn',
  'Mes.User.Login.Successfully': 'Đăng nhập thành công',
  'Mes.User.NotFound.Email': 'Không tìm thấy email',
  'Mes.OAuth.InvalidFormat.RedirectUri': 'Redirect URI không hợp lệ',
  'Mes.User.Required.Name': 'Tên người dùng là bắt buộc',
  'Mes.Role.Create.Successfully': 'Tạo vai trò thành công',
  'Mes.Role.Create.Failed': 'Tạo vai trò thất bại',
  'Mes.Role.Update.Successfully': 'Cập nhật vai trò thành công',
  'Mes.Role.Update.Failed': 'Cập nhật vai trò thất bại',
  'Mes.Role.Search.Successfully': 'Xem danh sách vai trò thành công',
  'Mes.Role.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Role.Delete.Successfully': 'Xóa vai trò thành công',
  'Mes.Role.Delete.Failed': 'Xóa vai trò thất bại',
  'Mes.Role.Required.PermissionCodes': 'Mã quyền là bắt buộc',
  'Mes.Role.OverLength.Code': 'Mã vai trò không vượt quá 255 kí tự',
  'Mes.Role.OverLength.Description': 'Mô tả vai trò không vượt quá 255 kí tự',
  'Mes.Role.AlreadyExist.Code': 'Mã vai trò đã tồn tại',
  'Mes.Role.Required.Code': 'Mã vai trò là bắt buộc',
  'Mes.Role.NotWhiteSpace.Code': 'Mã vai trò không có khoảng trắng',
  'Mes.Role.NotSpecialCharacter.Code': 'Mã vai trò không chứa ký tự đặc biệt(trừ dấu . @)',
  'Mes.Role.Required.Name': 'Tên vai trò là bắt buộc',
  'Mes.Role.OverLength.Name': 'Tên vai trò không vượt quá 255 kí tự',
  'Mes.Role.NotFound': 'Vai trò không tồn tại',
  'Mes.Role.Repeated.Ids': 'Id vai trò không lặp lại',
  'Mes.Role.Required.Ids': 'Id vai trò bắt buộc',
  'Mes.Role.WasUsed': 'Vai trò đã được sử dụng',
  'Mes.User.Create.Successfully': 'Tạo người dùng thành công',
  'Mes.User.Create.Failed': 'Tạo người dùng thất bại',
  'Mes.User.Update.Successfully': 'Cập nhật người dùng thành công',
  'Mes.User.Update.Failed': 'Cập nhật người dùng thất bại',
  'Mes.User.Search.Successfully': 'Xem danh sách người dùng thành công',
  'Mes.User.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.User.Delete.Successfully': 'Xóa người dùng thành công',
  'Mes.User.Delete.Failed': 'Xóa người dùng thất bại',
  'Mes.User.Send.Failed': 'Gửi cho người dùng mail thất bại',
  'Mes.User.CheckRecoveryCode.Successfully': 'Kiểm tra mã khôi phục thành công',
  'Mes.User.SendRecoveryCode.Successfully': 'Gửi mã khôi phục thành công',
  'Mes.User.ResetPassword.Successfully': 'Lấy lại mật khẩu thành công',
  'Mes.User.Refresh.Successfully': 'Làm mới thành công',
  'Mes.User.ChangePassword.Successfully': 'Đổi mật khẩu thành công',
  'Mes.User.Profile.Successfully': 'Lấy thông tin cá nhân thành công',
  'Mes.User.Required.TimezoneId': 'Múi giờ là bắt buộc',
  'Mes.User.Required.NotFound': 'Múi giờ không tồn tại',
  'Mes.User.Required.RedirectUri': 'Url chuyển hướng là bắt buộc',
  'Mes.User.OverLength.RedirectUri': 'Url chuyển hướng không vượt quá 255 kí tự',
  'Mes.User.Required.Code': 'Mã đăng nhập là bắt buộc',
  'Mes.User.OverLength.Code': 'Mã đăng nhập không vượt quá 255 kí tự',
  'Mes.User.Required.PhoneNumber': 'Số điện thoại là bắt buộc',
  'Mes.User.OverLength.IdentifierNumber': 'CMND/CCCD không vượt quá 50 kí tự',
  'Mes.User.Invalid.IdentifierNumber': 'CMND/CCCD không hợp lệ',
  'Mes.User.Repeated.Ids': 'Id người dùng không lặp lại',
  'Mes.User.Required.Ids': 'Id người dùng bắt buộc',
  'Mes.User.NotAllowed': 'Người dùng không được phép',
  'Mes.SsoResult.Empty.Id': 'Không tìm được id của người dùng trên sso',
  'Mes.SsoAuthentication.Failed': 'Đăng nhập Sso thất bại',
  'Mes.SsoResult.Empty.UserName': 'Không tìm được tên đăng nhập của người dùng trên sso',
  'Messages.SsoAuthentication.Required.AccessToken': 'Mã truy cập khi đăng nhập Sso là bắt buộc',
  'Mes.SsoAuthentication.Invalid.Email': 'Sso xác nhận Email không hợp lệ',
  'Mes.SsoAuthentication.Invalid.PhoneNumber': 'Sso xác nhận số điện thoại không hợp lệ',
  'Mes.SsoAuthentication.Invalid.Username': 'Sso xác nhận tên đăng nhập không hợp lệ',
  'Mes.SsoAuthentication.Invalid.Groups':
    'Nhóm người dùng Sso không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.SsoAuthentication.Invalid.ClientId':
    'ClientId không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.SsoAuthentication.Invalid.ClientSecret':
    'ClientSecret không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.SsoAuthentication.Invalid.GrantType':
    'GrantType không hợp lệ, vui lòng liên hệ quản trị viên của hệ thống để xử lí!',
  'Mes.UserMobileDevice.Required.DeviceId': 'Mã đăng kí thiết bị là bắt buộc',
  'Mes.UserMobileDevice.OverLength.DeviceId': 'Mã đăng kí thiết bị không vượt quá 255 kí tự',
  'Mes.User.NotFound.Username': 'Không tìm thấy người dùng',
  'Mes.User.Required.VerifyCode': 'Mã xác thực là bắt buộc',
  'Mes.LoginSession.NotFound': 'Không tìm thấy phiên đăng nhập',
  'Mes.LoginSession.Expired': 'Phiên đăng nhập đã hết hạn',
  'Mes.TwoFa.Invalid.Cache': 'Lỗi bộ nhớ đệm xác thực hai yếu tố',
  'Mes.TwoFa.NotFound': 'Không tìm thấy xác thực hai yếu tố',
  'Mes.TwoFa.NotFound.SecretKey': 'Không tìm thấy khóa bí mật xác thực hai yếu tố',
  'Mes.TwoFaMethod.NotAllowed': 'Phương thức xác thực hai yếu tố không được phép',
  'Mes.TwoFaMethod.NotFound': 'Phương thức xác thực hai yếu tố không tìm thấy',
  'Mes.UserTwoFaRequest.NotFound.TwoFaMethodType':
    'Không tìm thấy loại phương thức xác thực hai yếu tố trong yêu cầu',
  'Mes.UserTwoFaRequest.Required.TwoFaMethodType': 'Yêu cầu loại phương thức xác thực hai yếu tố',
  'Mes.User.Invalid.Password': 'Mật khẩu không hợp lệ',
  'Mes.User.Required.Password': 'Cần nhập mật khẩu',
  'Mes.User.NotAvailable': 'Người dùng không khả dụng',
  'Mes.User.Expired.SessionId': 'Phiên đăng nhập đã hết hạn',
  'Mes.User.Update': 'Cập nhật người dùng không thành công',
  'Mes.User.Blocked': 'Tài khoản người dùng bị khóa',
  'Mes.User.Invalid.TOTP': 'Mã TOTP không hợp lệ',
  'Mes.User.Required.TwoFactor': 'Cần xác thực hai yếu tố',
  'Mes.User.NotFound.TwoFa': 'Không tìm thấy xác thực hai yếu tố của người dùng',
  'Mes.User.Invalid.Sid2f': 'Mã SID xác thực hai yếu tố không hợp lệ',
  'Mes.UserRefreshToken.Invalid.Token': 'Mã thông báo làm mới không hợp lệ',
  'Mes.UserRefreshToken.WasUsed': 'Mã thông báo làm mới đã được sử dụng',
  'Mes.TwoFa.Create.Successfully': 'Tạo phương thức xác thực thành công',
  'Mes.TwoFa.Delete.Successfully': 'Xóa phương thức xác thực thành công',
  'Mes.TwoFa.Update.Successfully': ' Tắt xác thực 2 lớp thành công',
  'Mes.User.WasUsed.RecoveryCode': 'Mã dự phòng đã được sử dụng',
  'Mes.User.TwoFaAccountVerification.Successfully': 'Xác thực người dùng bằng mật khẩu thành công',
  'Mes.User.UpdateProfile.Successfully': 'Cập nhật profile thành công',
  'Mes.TwoFaMethod.Search.Successfully':
    'Xem danh sách phương thức xác thực của người dùng thành công',
  'Mes.LoginSession.Search.Successfully': 'Xem danh sách hoạt động đăng nhập thiết bị thành công',
  'Mes.LoginSession.Update.Successfully': 'Đăng xuất thiết bị từ xa thành công',
  'Mes.User.DeleteAvatarProfile.Successfully': 'Xoá Avatar thành công',
  'Mes.User.UpdateAvatarProfile.Successfully': 'Cập nhật avatar thành công',
  'Mes.User.Required.Avatar': 'Yêu cầu nhập Avatar',
  'Mes.User.Invalid.Avatar': 'Avatar không hợp lệ',
  'Mes.User.NotEnough.RecoveryCode': 'Mã khôi phục không đủ.',
  'Mes.User.RecoveryCode.WasUsed': 'Mã dự phòng đã được sử dụng.',
  'Mes.TwoFa.NotMatch.Code': 'Mã xác thực không hợp lệ',
};
