import './style.scss';

import { Form, Input, Modal } from 'antd';
import React, { useMemo } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';

import authenticationPresenter from '@modules/authentication/presenter';
import { UserBaseSelector } from '@modules/authentication/profileStore';
import { useSingleAsync } from '@shared/hook/useAsync';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface IModalInfo {
  isOpen: boolean;
  type?: string;
}

interface IAccountModal {
  modal: IModalInfo;
  onClose: () => void;
}

const AccountModal = ({ modal, onClose }: IAccountModal) => {
  const { formatMessage } = useAltaIntl();
  const [form] = Form.useForm();
  const userStore = useSelector(UserBaseSelector);
  const updateProfileCall = useSingleAsync(authenticationPresenter.updateProfile);
  const device = useDeviceType();

  const currentFormItem = useMemo(() => {
    const objectContent: any = {
      name: (
        <div className="fullName-box">
          <Form.Item
            name="fullName"
            label={formatMessage('profile.form.fullName')}
            rules={[
              { required: true },
              { max: 255, message: formatMessage('profile.form.fullName.required.255') },
              {
                validator: (_, value: any) => {
                  if (value && value?.trim() === '') {
                    return Promise.reject(
                      new Error(formatMessage('profile.form.fullName.required.space')),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder={formatMessage('profile.form.fullName.pla')} maxLength={256} />
          </Form.Item>
          <div className="form-profile-note">{formatMessage('profile.form.note')}</div>
        </div>
      ),
      idCard: (
        <Form.Item
          label={formatMessage('profile.form.idCard')}
          name="idCard"
          className="idCard-box"
          rules={[{ max: 255, message: formatMessage('profile.form.idCard.required.255') }]}
        >
          <Input placeholder={formatMessage('profile.form.idCard.pla')} maxLength={256} />
        </Form.Item>
      ),
    };

    return !modal?.type ? objectContent.name : objectContent?.[modal?.type];
  }, [modal]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const handleFinish = (values: any) => {
    const payload = {
      email: userStore?.email,
      fullName: userStore?.fullName,
      username: userStore?.username,
      phoneNumber: userStore.phoneNumber,
      idCard: userStore.idCard,
      ...values,
    };
    updateProfileCall
      .execute(payload)
      .then(() => {
        handleCancel();
      })
      .catch(() => {});
  };

  return (
    <Modal
      centered={device !== ETypeDevice.DESKTOP}
      open={modal.isOpen}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      closeIcon={<X />}
      className="account-modal"
      cancelText={formatMessage('common.back')}
      okText={formatMessage('common.button.accept')}
      title={formatMessage(`profile.modal.${modal.type}.title`)}
      confirmLoading={updateProfileCall.status === 'loading'}
      destroyOnClose
    >
      <div className="account-modal-des">{formatMessage(`profile.modal.${modal.type}.des`)}</div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className="main-form"
        name="userProfileForm"
        initialValues={userStore}
        onFinish={handleFinish}
        onResetCapture={() => {}}
      >
        {currentFormItem}
      </Form>
    </Modal>
  );
};

export default React.memo(AccountModal);
